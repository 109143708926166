import React, { useState } from "react";

import clsx from "clsx";
import styles from "./styles.module.css";
import { Typography } from "antd";
import HomeMain from "./HomeMain";
import useScreenDimensions from "Utility/Dimention";

const Home = () => {
  const { width } = useScreenDimensions();

  return (
    <div className={clsx(styles.main)}>
      <div className={clsx(styles.headerMain)}>
        <img src="/img/connectLogo.png" className={clsx(styles.headerLogo)} />
        <div className={clsx(styles.profile)}>
          <Typography className={clsx(styles.text)}>RS</Typography>
        </div>
      </div>
      <div className={clsx(styles.mainBody)}>
        <HomeMain />
      </div>
    </div>
  );
};

export default Home;
