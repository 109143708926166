import { sendRequest } from "../sendRequest";
import { queryParamsArrayBuilder } from "../../Utility/queryParamsArrayBuilder";

export const getConnectedAccounts = ({ limit, connectionStatus = [] }) => {
  const connectionStatusMapped = queryParamsArrayBuilder(
    "connectionStatus",
    connectionStatus
  );
  const queryKey = ["accounts", limit, connectionStatusMapped];
  const queryFunction = ({ pageParam }) =>
    sendRequest({
      method: "get",
      url: `/influencer/social-media/connected?limit=${limit}&pageNo=${
        pageParam || 1
      }${connectionStatus?.length ? `&${connectionStatusMapped}` : ""}`,
      isV2: true,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
