import clsx from "clsx";
import React from "react";
import { Typography } from "antd";

import { useQuery } from "react-query";

import { getMe } from "handlers/me";

import styles from "./../styles.module.css";

const Header = ({ isLoggedIn = false }) => {
  const { queryKey, queryFunction } = getMe();

  const { data: userData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(isLoggedIn),
  });

  return (
    <React.Fragment>
      <header className={clsx(styles.headerMain)}>
        <img
          src="/img/connectLogo.png"
          className={clsx(styles.headerLogo)}
          alt="cloutflow connect"
        />
        {isLoggedIn && (
          <div className={clsx(styles.profile)}>
            <Typography className={clsx(styles.text)}>
              {userData?.data?.name?.at(0)}
              {userData?.data?.lastname?.at(0)}
            </Typography>
          </div>
        )}
      </header>
    </React.Fragment>
  );
};

export default Header;
