import React from "react";
import { useState } from "react";

import { Space, Select } from "antd";
import { OtpInput } from "reactjs-otp-input";

import InputMain from "components/Shared/Input";

import { useGlobalLinkStateContext } from "context/GlobalLinkStateContext";
import { countryCodeArray } from "assets/country_code";

import styles from "./styles.module.css";

const { Option } = Select;

const OTPBrand = () => {
  const { buttonState, globalDetails, setGlobalDetails } =
    useGlobalLinkStateContext();

  //   const [timer, setTimer] = useState({
  //     seconds: 60,
  //     isRunning: false,
  //   });

  const [touched, setTouched] = useState({
    name: false,
    phoneNumber: false,
  });

  //   useEffect(() => {
  //     let interval = null;
  //     if (timer.isRunning) {
  //       interval = setInterval(() => {
  //         setTimer((prevTimer) => ({
  //           seconds: prevTimer.seconds === 1 ? 60 : prevTimer.seconds - 1,
  //           isRunning: prevTimer.seconds === 1 ? false : true,
  //         }));
  //       }, 1000);
  //     } else if (!timer.isRunning && timer.seconds !== 0) {
  //       clearInterval(interval);
  //     }
  //     return () => clearInterval(interval);
  //   }, [timer.isRunning, timer.seconds]);

  //   const startTimer = () => {
  //     setTimer((prevTimer) => ({
  //       ...prevTimer,
  //       isRunning: true,
  //     }));
  //   };

  if (buttonState === "start") return <div />;

  return (
    <div style={{ display: "grid", gap: "20px" }}>
      {buttonState === "otp" ? (
        <>
          {/* <InputMain
            mainText={"Name"}
            value={globalDetails.name}
            handleChange={(val) =>
              setGlobalDetails((globalDetails) => ({
                ...globalDetails,
                name: val,
              }))
            }
            errorMessage={
              globalDetails?.name.trim() === "" && touched.name
                ? "Please Enter your Name"
                : undefined
            }
            onClick={() => {
              setTouched((touched) => ({ ...touched, name: true }));
            }}
            onTouchEnd={() => {
              setTouched((touched) => ({ ...touched, name: true }));
            }}
          /> */}

          <div style={{ display: "flex", gap: "0" }}>
            <Select
              size={"large"}
              className="cccSelect"
              value={globalDetails.countryCode}
              onChange={(value) =>
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  countryCode: value,
                }))
              }>
              {countryCodeArray.map((item) => (
                <Option value={item.dial_code} key={item.name}>
                  {item.flag} {item.dial_code}
                </Option>
              ))}
            </Select>

            <InputMain
              mainText={"Phone number"}
              phoneNumber={true}
              type={"tel"}
              value={globalDetails.phoneNumber}
              handleChange={(val) =>
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  phoneNumber: val,
                }))
              }
              onClick={() => {
                setTouched((touched) => ({ ...touched, phoneNumber: true }));
              }}
              onTouchEnd={() => {
                setTouched((touched) => ({ ...touched, phoneNumber: true }));
              }}
            />
          </div>
          {/* <div className={styles.platformContainer}>
            <p className={styles.platformLabel}> Select Platform to Connect</p>

            <OptionTile
              username={`Instagram`}
              platform="instagram"
              isSelected={globalDetails.platform === "instagram"}
              handleClick={() => {
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  platform: "instagram",
                }));
              }}
              key={"1"}
            />

            <OptionTile
              username={`Youtube`}
              platform="youtube"
              isSelected={globalDetails.platform === "youtube"}
              handleClick={() => {
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  platform: "youtube",
                }));
              }}
              key={"2"}
            />
          </div> */}
        </>
      ) : (
        <>
          <OtpInput
            value={globalDetails.otp}
            onChange={(value) => {
              setGlobalDetails((globalDetails) => ({
                ...globalDetails,
                otp: String(value),
              }));
            }}
            numInputs={6}
            isInputNum
            // className={clsx(styles.input)}
            containerStyle={{ display: "flex", gap: "5px" }}
            inputStyle={{
              width: "20px",
              height: "25px",
              fontSize: "1rem",
              borderRadius: 8,
              border: "1px solid #FFF",
              color: "#FFF",
              backgroundColor: "transparent",
            }}

            // separator={<span>-</span>}
          />
          <p className={styles.sendConfirm}>
            OTP sent to{" "}
            {globalDetails?.phoneNumber && (
              <span>
                {globalDetails?.phoneNumber?.slice(0, 4)}
                {Array((globalDetails?.phoneNumber).length - 6)
                  .fill("*")
                  .join("")}
                {globalDetails?.phoneNumber?.slice(-2)}
              </span>
            )}
          </p>
        </>
      )}
    </div>
  );
};

export default OTPBrand;
