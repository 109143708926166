import { sendRequest } from "../sendRequest";

export const connectIg = ({ data, token }) => {
  return sendRequest({
    method: "post",
    url: "/influencer/social-media-access/instagram/get-eligibility",
    body: { ...data },
    options: {
      Authorization: `Bearer ${token}`,
    },
    isV2: true,
  });
};
