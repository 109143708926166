import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import TitleMain from "components/Shared/Typography/TitleMain";
import clsx from "clsx";
import styles from "./styles.module.css";
import useScreenDimensions from "Utility/Dimention";
import LoginMain from "./LoginMain";
import LoginPhone from "./LoginPhone";
import LoginOtp from "./LoginOtp";

const Login = () => {
  const { width } = useScreenDimensions();
  const [loginState, setLoginState] = useState(0);

  // Framer Motion animation variants
  const variants = {
    hidden: { opacity: 0, y: -10, transition: { duration: 0.2 } },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2 } },
    exit: { opacity: 0, y: 10, transition: { duration: 0.2 } },
  };

  return (
    <div className={clsx(styles.main)}>
      <div className={clsx(styles.leftMain)}>
        <div className={clsx(styles.headerMain)}>
          <img src="/img/connectLogo.png" className={clsx(styles.headerLogo)} />
        </div>
        <div className={clsx(styles.body)}>
          <div className={clsx(styles.textBody)}>
            <TitleMain
              text="🔒Securely share your social data via social media login"
              isParagraph={true}
              start={21}
              end={34}
              customStyle={{ fontSize: "26px", fontWeight: "700" }}
            />
          </div>
          <AnimatePresence mode="wait">
            {loginState === 0 && (
              <motion.div
                key="loginMain"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={variants}
              >
                <LoginMain setLoginState={setLoginState} />
              </motion.div>
            )}
            {loginState === 1 && (
              <motion.div
                key="loginPhone"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={variants}
              >
                <LoginPhone setLoginState={setLoginState} />
              </motion.div>
            )}
            {loginState === 2 && (
              <motion.div
                key="loginOtp"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={variants}
              >
                <LoginOtp setLoginState={setLoginState} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {width > 1000 && (
        <div className={clsx(styles.rightMain)}>
          <img className={clsx(styles.mainImage)} src="/img/main.jpg" />
        </div>
      )}
    </div>
  );
};

export default Login;
