import React from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import MainButton from "components/Shared/Button/MainButton";
import InputMain from "components/Shared/Input";
import { Typography } from "antd";
import InputEdit from "components/Shared/InputEdit";

const LoginPhone = ({ setLoginState }) => {
  const handleChange = (data) => {
    console.log(data.target.value);
  };
  return (
    <div className={clsx(styles.innerBody)}>
      <InputMain
        mainText="Enter your Phone Number"
        type="tel"
        phoneNumber={true}
        name="phone"
        onChange={handleChange}
      />
      <MainButton text="Verify Phone Number" onClick={() => setLoginState(2)} />
      <Typography className={clsx(styles.text2)}>
        By continuing, you agree to Cloutflow's{" "}
        <a href="https://cloutflow.com/terms-condition">Terms of Service</a> and{" "}
        <a href="https://cloutflow.com/privacy-policy"> Privacy Policy.</a>
      </Typography>
    </div>
  );
};

export default LoginPhone;
