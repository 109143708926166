import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Spin } from "antd";

import MainButton from "components/Shared/Button/MainButton";

import { useMutation } from "react-query";

import { logBackend } from "handlers/logBackend";

const AcceptInvite = () => {
  const [searchParams, _] = useSearchParams();

  const acceptRequestId = searchParams.get("acceptRequestId");

  const url = "/influencer/connect-request/accept-discovery";

  const customSendRequest = () =>
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1${url}`,
      data: {},
      //withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${acceptRequestId}`,
      },
    }).catch(async (err) => {
      await logBackend(url, { acceptRequestId }, JSON.stringify(err));

      // message.error(errorMessage);

      throw err;
    });

  const acceptInviteMutation = useMutation(customSendRequest);

  const navigate = useNavigate();

  useEffect(() => {
    acceptInviteMutation.mutate();
  }, []);

  if (acceptInviteMutation.isLoading) {
    return (
      <Row justify={"center"} style={{ marginBlock: 100 }}>
        <Spin />
      </Row>
    );
  }

  return (
    <div style={{ marginBlock: 300, padding: 20 }}>
      <p
        style={{
          color: " rgba(255, 255, 255, 0.9)",
          fontSize: "22px",
          fontWeight: 600,
        }}>
        {acceptInviteMutation.isSuccess
          ? "Invitation Accepted ✅ "
          : "Something Went wrong while accepting invite"}
      </p>
      <Row justify={"center"} style={{ marginTop: 20 }}>
        <MainButton
          onClick={() => navigate("/")}
          text={
            acceptInviteMutation.isSuccess ? "Manage Communities" : "Go Back"
          }
        />
      </Row>
    </div>
  );
};

export default AcceptInvite;
