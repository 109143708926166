import { sendRequest } from "../sendRequest";

export const selectIg = ({ data, token }) => {
  return sendRequest({
    method: "post",
    url: "/influencer/social-media-access/instagram",
    body: { ...data },
    options: {
      Authorization: `Bearer ${token}`,
    },
  });
};
