import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import queryString from "query-string";

import PermissionErrorDrawer from "components/PlatformConnect/FacebookErrorDrawer/PermissionsError";
import InternalError from "components/PlatformConnect/FacebookErrorDrawer/InternalError";
import NoInstaConnected from "components/PlatformConnect/FacebookErrorDrawer/NoInstaConnected";
import NoInstaFbPage from "components/PlatformConnect/FacebookErrorDrawer/NoInstaFbPage";
import AlreadyLinked from "components/PlatformConnect/FacebookErrorDrawer/AlreadyLinked";
import DifferentErrorsDrawer from "components/PlatformConnect/FacebookErrorDrawer/DifferentErrors";
import NoFbPageDrawer from "components/PlatformConnect/FacebookErrorDrawer/NoFbPage";
import OptionTile from "./OptionTile";

import animationData from "assets/lottie/tickLoading.json";
import { capitalizeFirstLetter } from "../../../Utility/capitalizeFirstLetter";
import { getYtOptions } from "../FooterButton/utils/getYtOptions";

import styles from "./styles.module.css";

import { useStateContext } from "context/StateContext";
import { useAuthContext } from "context/AuthContext";
import { connectIgV2, connectYtV2 } from "handlers/connectSocial";
import { getState, getAppState, getPublicDetails } from "handlers/me";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Socials = () => {
  const {
    code,
    fbState,
    connectRequestId,
    userIgs,
    setUserIgs,
    setAccessToken,
    selectedIg,
    setSelectedIg,
    state,
    ytState,
    userYts,
    setUserYts,
    selectedYt,
    setSelectedYt,
  } = useStateContext();
  const { signedIn } = useAuthContext();

  const queryClient = useQueryClient();

  const { queryKey: publicDataKey, queryFunction: publicDataFn } =
    getPublicDetails({ connectRequestId });

  const { data: publicData } = useQuery(publicDataKey, publicDataFn);

  const { queryKey: stateQueryKey, queryFunction: stateQueryFunction } =
    getAppState({ connectRequestId });

  const { data: appStateData, isLoading: appStateLoading } = useQuery(
    stateQueryKey,
    stateQueryFunction,
    {
      enabled: Boolean(connectRequestId) && Boolean(signedIn),
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [permissionErrorDrawer, setPermissionErrorDrawer] = useState(false);
  const [internalErrorDrawer, setInternalErrorDrawer] = useState(false);
  const [noInstaConnectedDrawer, setNoInstaConnectedDrawer] = useState(false);
  const [noInstaFbPageDrawer, setNoInstaFbPageDrawer] = useState(false);
  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showDiffErrorDrawer, setShowDiffErrorDrawer] = useState(false);
  const [noFbDrawerVisible, setNoFbDrawerVisible] = useState(false);

  const connectIgMutation = useMutation(connectIgV2);
  const connectYtMutation = useMutation(connectYtV2);

  const checkIsErrorSame = (accounts) => {
    if (accounts?.every((accountItem) => !accountItem.isPageLinkedToInsta)) {
      setNoInstaFbPageDrawer(true);
      return true;
    }
    if (
      accounts?.every(
        (pageItem) =>
          pageItem.isPageLinkedToInsta === true &&
          pageItem.isInstaPageSelectedWithPage === false
      )
    ) {
      setNoInstaConnectedDrawer(true);
      return true;
    }

    if (
      accounts?.every(
        (account) => account.reason === "existingOtherAccountHasIgUser"
      )
    ) {
      setAlreadyLinked(true);
      return true;
    }

    if (accounts?.every((account) => account.reason === "error")) {
      setInternalErrorDrawer(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (code && fbState) {
      return connectIgMutation.mutate(
        {
          data: {
            code: code,
            redirectUri: `${process.env.REACT_APP_URL}`,
          },
          token: fbState,
        },
        {
          onSuccess: (res) => {
            const resData = res?.data?.data;
            if (res.status === 200) {
              if (resData?.isScopeNotFound) {
                return setPermissionErrorDrawer(true);
              }
              if (resData?.connectAccounts?.length === 0) {
                return setNoFbDrawerVisible(true);
              }

              setUserIgs(resData?.connectAccounts);
              queryClient.invalidateQueries(stateQueryKey);

              if (
                resData?.connectAccounts?.some(
                  (account) => account?.isErrorConnectingOnApp
                )
              ) {
                if (!checkIsErrorSame(resData?.connectAccounts)) {
                  return setShowErrorMessage(true);
                }
              }
            }
          },
        }
      );
    } else if (code && ytState) {
      return connectYtMutation.mutate(
        {
          data: {
            code: code,
            redirectUri: `${process.env.REACT_APP_URL}`,
          },
          token: ytState,
        },
        {
          onSuccess: (res) => {
            const resData = res?.data?.data;
            if (res.status === 200) {
              if (resData?.isScopeNotFound) {
                return setPermissionErrorDrawer(true);
              }
              if (resData?.connectAccounts?.length === 0) {
                return setInternalErrorDrawer(true);
              }

              setUserYts(resData?.connectAccounts);
              queryClient.invalidateQueries(stateQueryKey);

              if (
                resData?.connectAccounts?.some(
                  (account) =>
                    account.reason === "existingOtherAccountHasYtUser"
                )
              ) {
                return setAlreadyLinked(true);
              }
            }
          },
        }
      );
    } else {
      setUserIgs([]);
      setAccessToken(null);
    }
  }, [code, fbState, ytState]);

  const { queryKey, queryFunction } = getState();

  const { data, isLoading: isLoadingState } = useQuery(
    queryKey,
    queryFunction,
    {
      enabled: Boolean(state?.social?.opened) && Boolean(signedIn),
    }
  );

  const url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}*instagram*${connectRequestId}`;

  const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
    getYtOptions()
  )}&state=${data?.data}*youtube*${connectRequestId}`;

  const platform = publicData?.data?.platform;

  if (
    connectIgMutation.isLoading ||
    appStateLoading ||
    connectYtMutation?.isLoading
  ) {
    return (
      <div>
        <p className={styles.heading}>
          Connect {capitalizeFirstLetter(platform)}
        </p>
        <p className={styles.subLabel}>
          Powered by{" "}
          <img className={styles.meta} src="/img/metaOnlyLogo.png" alt="meta" />
        </p>

        <Row justify="center" style={{ width: "100%" }}>
          <Lottie options={defaultOptions} height={160} width={160} />
        </Row>
      </div>
    );
  }

  if (
    appStateData?.data?.socialMediaAccounts?.instagram?.length === 0 &&
    (!code || !fbState) &&
    platform === "instagram"
  ) {
    return (
      <div>
        <p className={styles.heading}>Connect Instagram</p>
        <p className={styles.subLabel}>
          Powered by{" "}
          <img className={styles.meta} src="/img/metaOnlyLogo.png" alt="meta" />
        </p>
        <p className={styles.connectDesc}>
          💁🏽You need a business/creator Instagram account and the account
          connected with a Facebook page to connect.
        </p>
        <a
          className={styles.link}
          href="https://www.facebook.com/business/help/connect-instagram-to-page">
          Need help with this? 🙋🏼
        </a>
      </div>
    );
  }

  if (
    appStateData?.data?.socialMediaAccounts?.youtube?.length === 0 &&
    (!code || !ytState) &&
    platform === "youtube"
  ) {
    return (
      <div>
        <p className={styles.heading}>Connect Youtube</p>
        <p
          className={styles.subLabel}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          Powered by{" "}
          <img
            className={styles.meta}
            src="/img/googleLogoFull.png"
            alt="google"
            style={{ height: 16, width: 50 }}
            // height={16}
            // width={50}
          />
        </p>
        <p className={styles.connectDesc}>
          Youtube connection is handled by Google, to continue sign in with
          google.
        </p>
        {/* <a
          className={styles.link}
          href="https://www.facebook.com/business/help/connect-instagram-to-page">
          Need help with this? 🙋🏼
        </a> */}
      </div>
    );
  }

  return (
    <div style={{ minHeight: "217px" }}>
      <p className={styles.connectDesc}>
        Select one {capitalizeFirstLetter(platform)} account to join the
        community.
      </p>
      <Row justify={"end"}>
        <div
          className={styles.addAccount}
          onClick={() => {
            if (isLoadingState) {
              return;
            }
            return window.open(platform === "instagram" ? url : ytUrl, "_self");
          }}>
          <BsPlus size={20} />
          Add Account
        </div>
      </Row>
      <div className={styles.accountContainer}>
        {platform === "instagram" ? (
          <>
            {" "}
            {appStateData?.data?.socialMediaAccounts?.instagram?.map(
              (ig, key) => {
                return (
                  <OptionTile
                    username={`@${ig?.socialMediaName}`}
                    isSelected={
                      ig?.socialMediaName === selectedIg?.socialMediaName
                    }
                    handleClick={() => {
                      setSelectedIg(ig);
                    }}
                    key={key}
                  />
                );
              }
            )}
          </>
        ) : (
          <>
            {appStateData?.data?.socialMediaAccounts?.youtube?.map(
              (yt, key) => {
                return (
                  <OptionTile
                    username={`@${yt?.socialMediaName}`}
                    platform={platform}
                    isSelected={yt?._id === selectedYt?._id}
                    handleClick={() => {
                      setSelectedYt(yt);
                    }}
                    key={key}
                  />
                );
              }
            )}
          </>
        )}
      </div>
      {showErrorMessage && (
        <div className={styles.connectDesc} style={{ marginTop: 20 }}>
          Some pages you were trying to connect failed.{" "}
          <span
            className={styles.bold}
            onClick={() => setShowDiffErrorDrawer(true)}>
            See failed pages
          </span>
        </div>
      )}
      <PermissionErrorDrawer
        visible={permissionErrorDrawer}
        handleBack={() => {
          setSearchParams(() => ({
            connectRequestId: searchParams.get("connectRequestId") || "",
          }));
          setPermissionErrorDrawer(false);
        }}
      />
      <InternalError
        visible={internalErrorDrawer}
        handleBack={() => {
          setSearchParams(() => ({
            connectRequestId: searchParams.get("connectRequestId") || "",
          }));
          setInternalErrorDrawer(false);
        }}
      />{" "}
      <NoInstaConnected
        visible={noInstaConnectedDrawer}
        handleBack={() => {
          setSearchParams(() => ({
            connectRequestId: searchParams.get("connectRequestId") || "",
          }));
          setNoInstaConnectedDrawer(false);
        }}
        accounts={userIgs}
      />
      <NoInstaFbPage
        visible={noInstaFbPageDrawer}
        handleBack={() => {
          setSearchParams(() => ({
            connectRequestId: searchParams.get("connectRequestId") || "",
          }));
          setNoInstaFbPageDrawer(false);
        }}
        accounts={userIgs}
      />
      <AlreadyLinked
        visible={alreadyLinked}
        handleBack={() => {
          setSearchParams(() => ({
            connectRequestId: searchParams.get("connectRequestId") || "",
          }));
          setAlreadyLinked(false);
        }}
        connectedAccounts={platform === "instagram" ? userIgs : userYts}
        platform={platform}
      />
      <DifferentErrorsDrawer
        visible={showDiffErrorDrawer}
        handleBack={() => setShowDiffErrorDrawer(false)}
        accounts={userIgs}
      />
      <NoFbPageDrawer
        visible={noFbDrawerVisible}
        handleBack={() => {
          setNoFbDrawerVisible(false);
        }}
      />
    </div>
  );
};

export default Socials;
