import { Drawer } from "antd";
import React from "react";

import styles from "./styles.module.css";

const ModalWrapper = ({ visible, setVisible, children }) => {
  return (
    <React.Fragment>
      <Drawer
        style={{
          backgroundColor: "#171f2a",
        }}
        bodyStyle={{
          padding: 0,
        }}
        width={"100vw"}
        open={visible}
        closable={false}
      >
        <div className={styles.modalHeader}>
          <button
            style={{
              backgroundColor: "transparent",
              padding: 0,
              margin: 0,
              border: 0,
              outline: 0,
              cursor: "pointer",
            }}
            onClick={() => setVisible(false)}
          >
            <img src={"/icons/keyboard-backspace.svg"} alt="back" />
          </button>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </Drawer>
    </React.Fragment>
  );
};

export default ModalWrapper;
