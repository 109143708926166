import React from "react";
import { useQuery } from "react-query";
import queryString from "query-string";
import { Modal, Space, Row, Button, Spin } from "antd";

import OptionTile from "components/Verify/Socials/OptionTile";

import { getState } from "handlers/me";

import { getYtOptions } from "components/Verify/FooterButton/utils/getYtOptions";

import styles from "./styles.module.css";

import "./styles.module.css";

const AddAccountModal = ({ visible, handleClose }) => {
  const { queryKey: stateKey, queryFunction: stateFn } = getState();

  const { data, isLoading } = useQuery(stateKey, stateFn, {
    enabled: visible,
  });

  //ConnectionUrl's

  const getConnectionLink = (platform) => {
    const fbUrl = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}v1&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}*instagram*basic`;

    const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
      getYtOptions(true)
    )}&state=${data?.data}*youtube`;

    return window.open(platform === "instagram" ? fbUrl : ytUrl);
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ type: "primary" }}
      cancelText={"Back"}
      bodyStyle={{
        backgroundColor: "#192227",
        margin: "-22px -24px",
        padding: "20px 24px",
        borderRadius: "8px",
      }}
      centered
      closeIcon={false}
      footer={null}
      className={styles.wrapper}

      //   title={<p style={{ backgroundColor: "#192227" }}>Select Platform</p>}
    >
      <h3 style={{ backgroundColor: "#192227" }}>Select Platform</h3>

      {isLoading ? (
        <Row justify={"center"} style={{ marginBlock: 10 }}>
          <Spin />
        </Row>
      ) : (
        <Space
          direction="vertical"
          style={{ width: "80%", marginBlock: 20 }}
          size={10}>
          <OptionTile
            username={`Instagram`}
            platform="instagram"
            isSelected={false}
            handleClick={() => {
              getConnectionLink("instagram");
            }}
            key={"1"}
          />

          <OptionTile
            username={`Youtube`}
            platform="youtube"
            isSelected={false}
            handleClick={() => {
              getConnectionLink("youtube");
            }}
            key={"2"}
          />
        </Space>
      )}
      <Row justify={"end"} style={{ marginTop: 10 }}>
        <Button type="primary" onClick={handleClose}>
          Back
        </Button>
      </Row>
    </Modal>
  );
};

export default AddAccountModal;
