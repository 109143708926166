import React from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const NoInstaConnected = ({ handleBack, visible, accounts }) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main>
        <p className={styles.heading} style={{ marginBottom: "30px" }}>
          You missed selecting Instagram
          <br /> account for the page 😅
        </p>
        <p className={styles.subHeading} style={{ marginBottom: "20px" }}>
          Please select the Instagram page to connect.
        </p>
        <div style={{ display: "grid", gap: "10px", marginBottom: 20 }}>
          {accounts
            ?.filter(
              (pageItem) =>
                pageItem?.isPageLinkedToInsta === true &&
                pageItem?.isInstaPageSelectedWithPage === false
            )
            .map((pageItem, idx) => {
              return (
                <p className={styles.subHeading} key={idx}>
                  {pageItem?.igUserName} is not selected
                </p>
              );
            })}
        </div>
        <img
          src="/img/fbInsta.png"
          style={{ height: "357px", width: "300px" }}
          alt="fb_error"
        />
      </main>
    </CustomDrawer>
  );
};

export default NoInstaConnected;
