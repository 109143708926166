import React from "react";
import OptionTile from "./OptionTile";

import styles from "./styles.module.css";

import { useGlobalLinkStateContext } from "context/GlobalLinkStateContext";

const SocialsBrand = () => {
  const { globalDetails, setGlobalDetails } = useGlobalLinkStateContext();
  return (
    <div className={styles.platformContainer}>
      <p className={styles.platformLabel}> Select Platform to Connect</p>

      <OptionTile
        username={`Instagram`}
        platform="instagram"
        isSelected={globalDetails.platform === "instagram"}
        handleClick={() => {
          setGlobalDetails((globalDetails) => ({
            ...globalDetails,
            platform: "instagram",
          }));
        }}
        key={"1"}
      />

      <OptionTile
        username={`Youtube`}
        platform="youtube"
        isSelected={globalDetails.platform === "youtube"}
        handleClick={() => {
          setGlobalDetails((globalDetails) => ({
            ...globalDetails,
            platform: "youtube",
          }));
        }}
        key={"2"}
      />
    </div>
  );
};

export default SocialsBrand;
