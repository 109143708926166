import React from "react";
import { Row } from "antd";

import styles from "./styles.module.css";

import { useStateContext } from "../../../context/StateContext";

const Community = () => {
  const { setState } = useStateContext();

  return (
    <div>
      <Row justify={"end"} style={{ marginBottom: "20px" }}>
        <div
          className={styles.link}
          onClick={() =>
            setState({
              otp: {
                opened: false,
                completed: true,
              },
              social: {
                opened: true,
                completed: false,
              },
              community: {
                opened: false,
                completed: false,
              },
            })
          }>
          Edit Accounts
        </div>
      </Row>
      <div className={styles.container}>
        <p className={styles.desc}>As a part of Brand’s community:</p>
        <p className={styles.desc}>
          <div style={{ display: "flex", gap: "2px" }}>
            1.{" "}
            <div>
              Brands would be able to find your <br />
              profile on Cloutflow for all their future campaigns.
            </div>
          </div>
          <div style={{ display: "flex", gap: "2px" }}>
            2.
            <div>View Insights of your Instagram profile.</div>
          </div>
        </p>
        <a className={styles.link} href="https://cloutflow.com/">
          🤔 How does this helps me?
        </a>
        <a className={styles.link} href="https://cloutflow.com/">
          🤔 Why do brand’s need this?
        </a>
        <p className={styles.desc}>
          You can withdraw from the community anytime from Cloutflow App.
        </p>
      </div>
    </div>
  );
};

export default Community;
