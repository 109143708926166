import React from "react";
import { Space } from "antd";

import { IoMdArrowRoundBack } from "react-icons/io";

const DrawerHeader = ({ handleClose = () => {} }) => {
  return (
    <div
      style={{ margin: "24px -4px ", cursor: "pointer" }}
      onClick={handleClose}>
      <IoMdArrowRoundBack color="#FFFFFF" size={30} />
    </div>
  );
};

export default DrawerHeader;
