import React from "react";

import styles from "./../styles.module.css";

const EmptyScreen = () => {
  return (
    <div>
      <h6 className={styles.emptyHeader}>Communities you are a part of</h6>
      <p className={styles.emptyText}>
        You are not a part of any community right now.
      </p>
    </div>
  );
};

export default EmptyScreen;
