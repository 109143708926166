import React from "react";
import { Drawer, Row } from "antd";

import DrawerHeader from "./Header";

const CustomDrawer = ({
  visible = false,
  handleClose = () => {},
  children = <div />,
  hasTitle = true,
}) => {
  return (
    <Drawer
      title={hasTitle ? <DrawerHeader handleClose={handleClose} /> : null}
      width={"100%"}
      placement={"right"}
      closable={false}
      onClose={handleClose}
      open={visible}
      bodyStyle={{ padding: "20px 40px" }}>
      {/* <Row justify="center"> */}
      <div style={{ maxWidth: "700px" }}>{children}</div>
      {/* </Row> */}
    </Drawer>
  );
};

export default CustomDrawer;
