import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useQuery } from "react-query";

import CustomDrawer from "components/Shared/CustomDrawer";

import { getState } from "handlers/me";

import styles from "./styles.module.css";

const FacebookErrorDrawer = ({
  errorData,
  handleBack,
  visible,
  errorType,
  connectRequestId,
}) => {
  const { queryKey, queryFunction } = getState();
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: visible,
  });

  // const url = `https://www.facebook.com/v13.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}/connect/instagram&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}`;

  //Testing Url
  const url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=$${data?.data}*instagram*${connectRequestId}`;

  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main className={styles.container}>
        <section className={styles.textContainer}>
          <p className={styles.heading}>
            {errorType === "permission"
              ? "You did not select all the permissions while connecting Instagram 😅"
              : errorType === "noInsta"
              ? "No Instagram account is connected with the pages you have selected😅 "
              : errorType === "noCreatedFb"
              ? "You don’t have any Facebook page 😅"
              : "You missed selecting Instagram account for the page 😅 "}
          </p>
          <p className={styles.subHeading}>
            {" "}
            {errorType === "permission" ? (
              <>
                <p>
                  Cloutflow connect requires all the permission to work
                  properly.{" "}
                </p>
                <p>Select all the permissions and try again!</p>
              </>
            ) : errorType === "noInsta" ? (
              <>
                <div>
                  <p>Facebook pages selected</p>
                  {errorData?.pages?.map((pageItem) => {
                    return (
                      <p className={styles.listItem}>{pageItem.pageName}</p>
                    );
                  })}
                </div>
                <p>
                  Either select the Facebook page that is connected to an
                  Instagram account, or
                  <br />{" "}
                  <a
                    className={styles.link}
                    href="https://help.instagram.com/570895513091465">
                    Connect your Instagram with your Facebook page.
                  </a>
                </p>
              </>
            ) : errorType === "noCreatedFb" ? (
              <p>
                To connect your Instagram profile Successfully ,You need to
                create a Facebook page and link it with your Instagram profile
              </p>
            ) : (
              <>
                <p>Please select the Instagram page to connect.</p>
                <p>You can select these accounts:</p>
                <div>
                  {errorData?.pages
                    ?.filter(
                      (pageItem) =>
                        pageItem?.isPageLinkedToInsta &&
                        pageItem.isInstaPageSelectedWithPage
                    )
                    .map((pageItem) => {
                      return (
                        <p className={styles.listItem}>{pageItem.pageName}</p>
                      );
                    })}
                </div>
              </>
            )}
          </p>
        </section>
        {errorType === "permission" ? (
          <img
            src="/img/fbPermission.png"
            style={{ height: "357px", width: "300px" }}
            alt="fb_error"
          />
        ) : errorType === "noInsta" ? (
          <img
            src="/img/fbPage.png"
            style={{ height: "357px", width: "300px" }}
            alt="fb_error"
          />
        ) : errorType === "noCreatedFb" ? (
          <div />
        ) : (
          <img
            src="/img/fbInsta.png"
            style={{ height: "357px", width: "300px" }}
            alt="fb_error"
          />
        )}
        <div
          className={clsx(styles.fbButton, {
            [styles.loading]: isLoading,
          })}
          onClick={() => {
            if (isLoading) {
              return;
            }
            return window.open(url, "_self");
          }}>
          <img
            src="/img/fLogo.svg"
            style={{ padding: "8px" }}
            alt="fb"
            height={24}
            width={24}
          />
          Continue with Facebook
          {isLoading && (
            <Spin
              size="small"
              style={{ color: "white", marginLeft: "12px" }}
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24 }}
                  spin
                  color="#FFFFFF"
                />
              }
            />
          )}
        </div>
      </main>
    </CustomDrawer>
  );
};

export default FacebookErrorDrawer;
