import clsx from "clsx";

import styles from "./styles.module.css";
import { Button } from "antd";

const MainButton = ({ text, onClick, isLoading, disabled, className }) => {
  return (
    <Button
      block
      className={clsx(styles.main, className)}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
export default MainButton;
