import React, { useRef, useEffect, useState } from "react";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getYtOptions } from "./utils/getYtOptions";
import queryString from "query-string";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../../Firebase";
import { useSearchParams } from "react-router-dom";

import MainButton from "components/Shared/Button/MainButton";

import {
  getState,
  getPublicDetails,
  acceptInvite,
  getAppState,
} from "handlers/me";
import { getConnectRequestId } from "handlers/me";
import { useStateContext } from "context/StateContext";
import { useAuthContext } from "context/AuthContext";

import styles from "./styles.module.css";

const FooterButton = ({ connectRequestId }) => {
  const {
    buttonState,
    updateButtonState,
    otp,
    uid,
    setUid,
    code,
    fbState,
    selectedIg,
    state,
    setState,
    ytState,
    selectedYt,
    brandId,
    globalDetails,
  } = useStateContext();
  const {
    sendOtp,
    verifyOtp,
    customSignIn,
    signedIn,
    footerFirstRef,
    loading,
    sendOtpFirebase,
    verifyOtpFirebase,
  } = useAuthContext();

  const { queryKey, queryFunction } = getState();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [firebaseOtpLoading, setFirebaseOtpLoading] = useState(false);

  const getConnectRequestIdMutation = useMutation(getConnectRequestId, {
    onSuccess: (response) => {
      setSearchParams({
        connectRequestId: response?.data?.data?.connectRequestId,
      });

      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: true,
          completed: false,
        },
        community: {
          opened: false,
          completed: false,
        },
      });
      setFirebaseOtpLoading(false);
    },
  });

  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled:
      Boolean(state?.social?.opened) && !Boolean(code) && !Boolean(fbState),
  });

  const { queryKey: stateQueryKey, queryFunction: stateQueryFunction } =
    getAppState({ connectRequestId });

  const { data: appStateData } = useQuery(stateQueryKey, stateQueryFunction, {
    enabled: Boolean(connectRequestId) && Boolean(signedIn),
  });

  const acceptInviteMutation = useMutation(acceptInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries(stateQueryKey);
      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: false,
          completed: true,
        },
        community: {
          opened: false,
          completed: true,
        },
      });
    },
    onError: () => {
      message.error("Something went wrong while joining community");
    },
  });

  useEffect(() => {
    if (
      appStateData?.data?.isConnectRequestAddedToCommunity &&
      footerFirstRef.current
    ) {
      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: false,
          completed: true,
        },
        community: {
          opened: false,
          completed: true,
        },
      });
      footerFirstRef.current = false;
      return;
    }
    if (
      (appStateData?.data?.socialMediaAccounts?.instagram?.length > 0 ||
        (code && fbState) ||
        (signedIn && !brandId)) &&
      footerFirstRef.current
    ) {
      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: true,
          completed: false,
        },
        community: {
          opened: false,
          completed: false,
        },
      });
      footerFirstRef.current = false;
      return;
    }
  }, [appStateData?.data, footerFirstRef.current]);

  const sendOtpMutation = useMutation(sendOtp, {
    onSuccess: (response) => {
      updateButtonState("verify");
      setUid(response?.data?.uid);
    },
    onError: () => {
      message.error("Something Went wrong while sending Otp");
    },
  });
  const verifyOtpMutation = useMutation(verifyOtp, {
    onSuccess: async (response) => {
      customSignIn(response?.data?.token).then(() => {
        setState({
          otp: {
            opened: false,
            completed: true,
          },
          social: {
            opened: true,
            completed: false,
          },
          community: {
            opened: false,
            completed: false,
          },
        });
      });
    },
    onError: () => {
      message.error("Invalid OTP");
    },
  });

  const { queryKey: publicDataKey, queryFunction: publicDataFn } =
    getPublicDetails({ connectRequestId });

  const { data: publicData } = useQuery(publicDataKey, publicDataFn, {
    enabled: Boolean(connectRequestId),
  });

  const phoneNumber = String(publicData?.data?.phoneNumber || "");

  const handleSendOtp = async () => {
    try {
      // auth.settings.appVerificationDisabledForTesting = true;

      if (!globalDetails?.phoneNumber.startsWith("+")) {
        return message.error("Enter the number with country code");
      }

      if (
        Number.isNaN(
          globalDetails?.phoneNumber.split("+").join("").split("-").join("")
        )
      )
        return message.error("Invalid Number");

      const phone = globalDetails?.phoneNumber.split("-").join("");
      setFirebaseOtpLoading(true);
      let appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      sendOtpFirebase(phone, appVerifier)
        .then((result) => {
          if (result?.verificationId) {
            updateButtonState("verify");
            setUid(result?.verificationId);
          } else {
            message.error(
              "We detected a suspicious login, Please refresh the app"
            );
          }
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        })
        .catch(async (err) => {
          message.error(err?.code);
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        });
    } catch (err) {
      // backend Api to throw error
      console.log("err", err);
      message.error(
        err?.code + "We detected a suspicious login, Please refresh the app"
      );

      setFirebaseOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setFirebaseOtpLoading(true);
    await verifyOtpFirebase(otp, uid)
      .catch(async (err) => {
        setFirebaseOtpLoading(false);

        return message.error(err?.code + " ,Please Retry");
      })
      .then(() => {
        getConnectRequestIdMutation.mutate({
          brandId,
          fullName: globalDetails.name,
          platform: globalDetails.platform,
        });
      });
  };

  const getButtonProps = () => {
    if (state?.otp?.opened) {
      if (brandId) {
        if (buttonState === "start") {
          return {
            text: "Enter your Details to Continue",
            loading: firebaseOtpLoading,
            disabled:
              !Boolean(globalDetails.phoneNumber) ||
              !Boolean(globalDetails.name.trim()) ||
              !Boolean(globalDetails.platform),

            onClick: () => {
              if (globalDetails.phoneNumber && globalDetails.name.trim()) {
                handleSendOtp();
              }
            },
          };
        }
        return {
          text: "Verify Otp",
          onClick: () => {
            handleVerifyOtp();
          },
          loading: firebaseOtpLoading,
          disabled: !Boolean(otp),
        };
      }

      if (buttonState === "start") {
        return {
          text: "Continue to verification",
          loading: sendOtpMutation.isLoading,
          disabled: !Boolean(phoneNumber),
          onClick: () => {
            if (phoneNumber) {
              sendOtpMutation.mutate({ phoneNumber, retryCount: 0 });
              // sendOtp(phoneNumber, 0).then(() => );
              // send otp
            }
          },
        };
      }
      return {
        text: "Verify Otp",
        onClick: () => {
          verifyOtpMutation.mutate({ otp, uid });
        },
        loading: verifyOtpMutation.isLoading || loading,
        disabled: !Boolean(otp),
      };
    }
    if (state?.social?.opened) {
      if (
        publicData?.data?.platform === "instagram" &&
        (appStateData?.data?.socialMediaAccounts?.instagram?.length > 0 ||
          (code && fbState))
      ) {
        return {
          text: "Continue",
          disabled: !Boolean(selectedIg?._id),
          onClick: () => {
            updateButtonState("");
            setState({
              otp: {
                opened: false,
                completed: true,
              },
              social: {
                opened: false,
                completed: true,
              },
              community: {
                opened: true,
                completed: false,
              },
            });
          },
        };
      }

      if (
        publicData?.data?.platform === "youtube" &&
        (appStateData?.data?.socialMediaAccounts?.youtube?.length > 0 ||
          (code && ytState))
      ) {
        return {
          text: "Continue",
          disabled: !Boolean(selectedYt?._id),
          onClick: () => {
            updateButtonState("");
            setState({
              otp: {
                opened: false,
                completed: true,
              },
              social: {
                opened: false,
                completed: true,
              },
              community: {
                opened: true,
                completed: false,
              },
            });
          },
        };
      }
    }
    if (state?.community?.opened) {
      return {
        text: "Join the Community",
        loading: acceptInviteMutation.isLoading,
        onClick: () => {
          updateButtonState("");
          acceptInviteMutation.mutate({
            connectRequestId,
            socialMediaKitId: selectedIg?._id || selectedYt?._id,
          });
        },
      };
    }

    return { text: "", onClick: () => {} };
  };

  //Testing Url
  const url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}*instagram*${connectRequestId}`;

  const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
    getYtOptions()
  )}&state=${data?.data}*youtube*${connectRequestId}`;

  return appStateData?.data?.isConnectRequestAddedToCommunity > 0 ? (
    <div />
  ) : (
    <div>
      {state?.social?.opened ? (
        publicData?.data?.platform === "instagram" ? (
          !code &&
          !fbState &&
          appStateData?.data?.socialMediaAccounts?.instagram?.length === 0 ? (
            <div
              className={clsx(styles.fbButton, {
                [styles.loading]: isLoading,
              })}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                return window.open(url, "_self");
              }}>
              <img
                src="/img/fLogo.svg"
                style={{ padding: "8px" }}
                alt="fb"
                height={24}
                width={24}
              />
              Continue with Facebook
              {isLoading && (
                <Spin
                  size="small"
                  style={{ color: "white", marginLeft: "12px" }}
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                      color="#FFFFFF"
                    />
                  }
                />
              )}
            </div>
          ) : (
            <MainButton
              type="primary"
              text={getButtonProps()?.text}
              onClick={() => getButtonProps()?.onClick()}
              isLoading={getButtonProps()?.loading || false}
              disabled={getButtonProps().disabled || false}
            />
          )
        ) : !code &&
          !ytState &&
          appStateData?.data?.socialMediaAccounts?.youtube?.length === 0 ? (
          <div
            className={clsx(styles.fbButton, {
              [styles.loading]: isLoading,
            })}
            style={{ justifyContent: "flex-start", paddingLeft: 20 }}
            onClick={() => {
              if (isLoading) {
                return;
              }
              return window.open(ytUrl, "_self");
            }}>
            <img
              src="/img/googleLogo.png"
              alt="google"
              height={24}
              width={24}
              style={{ marginRight: "10px" }}
            />
            Continue with Google
            {isLoading && (
              <Spin
                size="small"
                style={{ color: "white", marginLeft: "12px" }}
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24 }}
                    spin
                    color="#FFFFFF"
                  />
                }
              />
            )}
          </div>
        ) : (
          <MainButton
            type="primary"
            text={getButtonProps()?.text}
            onClick={() => getButtonProps()?.onClick()}
            isLoading={getButtonProps()?.loading || false}
            disabled={getButtonProps().disabled || false}
          />
        )
      ) : (
        <MainButton
          type="primary"
          text={getButtonProps()?.text}
          onClick={() => getButtonProps()?.onClick()}
          isLoading={getButtonProps()?.loading || false}
          disabled={getButtonProps().disabled || false}
        />
      )}
    </div>
  );
};

export default FooterButton;
