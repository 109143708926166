const SCOPE = [
  "https://www.googleapis.com/auth/youtube.readonly",
  "https://www.googleapis.com/auth/yt-analytics.readonly",
].join(" ");

const options = {
  scope: SCOPE,
  prompt: "consent",
  response_type: "code",
  access_type: "offline",
  redirect_uri: `${process.env.REACT_APP_URL}`,

  client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  include_granted_scopes: "true",
};

export const getYtOptions = (isV1 = false) => {
  if (isV1) {
    return { ...options, redirect_uri: `${process.env.REACT_APP_URL}v1` };
  }
  return options;
};
