import React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { useQuery, useMutation } from "react-query";
import { message } from "antd";
import { OtpInput } from "reactjs-otp-input";

import InputMain from "components/Shared/Input";
import OptionTile from "components/Verify/Socials/OptionTile";

import { useStateContext } from "context/StateContext";
import { useAuthContext } from "context/AuthContext";
import { getPublicDetails } from "handlers/me";

import styles from "./styles.module.css";

const OTP = () => {
  const {
    buttonState,
    setOtp,
    Otp,
    setUid,
    connectRequestId,
    brandId,
    globalDetails,
    setGlobalDetails,
  } = useStateContext();

  const { sendOtp } = useAuthContext();
  const { queryKey, queryFunction } = getPublicDetails({ connectRequestId });
  const [timer, setTimer] = useState({
    seconds: 60,
    isRunning: false,
  });

  const [touched, setTouched] = useState({
    name: false,
    phoneNumber: false,
  });

  const sendOtpMutation = useMutation(sendOtp, {
    onSuccess: (response) => {
      message.success("Otp Resent");
      setUid(response?.data?.uid);
      return startTimer();
    },
    onError: () => {
      message.error("Something Went wrong while resending Otp");
    },
  });

  const { data: publicData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(connectRequestId),
  });

  const phoneNumber = String(publicData?.data?.phoneNumber || "");

  useEffect(() => {
    if (buttonState === "verify") {
      return startTimer();
    }
    return setTimer({ isRunning: false, seconds: 60 });
  }, [buttonState]);

  useEffect(() => {
    let interval = null;
    if (timer.isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => ({
          seconds: prevTimer.seconds === 1 ? 60 : prevTimer.seconds - 1,
          isRunning: prevTimer.seconds === 1 ? false : true,
        }));
      }, 1000);
    } else if (!timer.isRunning && timer.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer.isRunning, timer.seconds]);

  const startTimer = () => {
    setTimer((prevTimer) => ({
      ...prevTimer,
      isRunning: true,
    }));
  };

  return (
    <div style={{ display: "grid", gap: "20px" }}>
      {brandId && buttonState !== "verify" && (
        <>
          <InputMain
            mainText={"Name"}
            value={globalDetails.name}
            handleChange={(val) =>
              setGlobalDetails((globalDetails) => ({
                ...globalDetails,
                name: val,
              }))
            }
            errorMessage={
              globalDetails?.name.trim() === "" && touched.name
                ? "Please Enter your Name"
                : undefined
            }
            onClick={() => {
              setTouched((touched) => ({ ...touched, name: true }));
            }}
            onTouchEnd={() => {
              setTouched((touched) => ({ ...touched, name: true }));
            }}
          />
          <InputMain
            mainText={"Phone number"}
            phoneNumber={true}
            type={"tel"}
            value={globalDetails.phoneNumber}
            handleChange={(val) =>
              setGlobalDetails((globalDetails) => ({
                ...globalDetails,
                phoneNumber: val,
              }))
            }
            onClick={() => {
              setTouched((touched) => ({ ...touched, phoneNumber: true }));
            }}
            onTouchEnd={() => {
              setTouched((touched) => ({ ...touched, phoneNumber: true }));
            }}
          />
          <div className={styles.platformContainer}>
            <p className={styles.platformLabel}> Select Platform to Connect</p>

            <OptionTile
              username={`Instagram`}
              platform="instagram"
              isSelected={globalDetails.platform === "instagram"}
              handleClick={() => {
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  platform: "instagram",
                }));
              }}
              key={"1"}
            />

            <OptionTile
              username={`Youtube`}
              platform="youtube"
              isSelected={globalDetails.platform === "youtube"}
              handleClick={() => {
                setGlobalDetails((globalDetails) => ({
                  ...globalDetails,
                  platform: "youtube",
                }));
              }}
              key={"2"}
            />
          </div>
        </>
      )}

      {buttonState === "verify" && (
        <>
          <OtpInput
            value={Otp}
            onChange={(value) => {
              setOtp(String(value));
            }}
            // handleChange={(value) => {
            //   console.log("hello", value);
            //   if (value) {
            //     console.log("setting this");
            //     setOtp(String(value));
            //   }
            // }}

            numInputs={6}
            isInputNum
            // className={clsx(styles.input)}
            containerStyle={{ display: "flex", gap: "5px" }}
            inputStyle={{
              width: "20px",
              height: "25px",
              fontSize: "1rem",
              borderRadius: 8,
              border: "1px solid #FFF",
              color: "#FFF",
              backgroundColor: "transparent",
            }}

            // separator={<span>-</span>}
          />
        </>
      )}
      {!brandId ||
        (brandId && buttonState === "verify" && (
          <p className={styles.sendConfirm}>
            OTP sent to{" "}
            {(phoneNumber || globalDetails?.phoneNumber) && (
              <span>
                {(phoneNumber || globalDetails?.phoneNumber)?.slice(0, 4)}
                {Array((phoneNumber || globalDetails?.phoneNumber).length - 6)
                  .fill("*")
                  .join("")}
                {(phoneNumber || globalDetails?.phoneNumber)?.slice(-2)}
              </span>
            )}
          </p>
        ))}
      {buttonState === "verify" && !brandId && (
        <p className={styles.subLabel}>
          Didn’t receive OTP?{" "}
          <span
            className={clsx(styles.resend, {
              [styles.disabled]: timer?.isRunning,
            })}
            onClick={() =>
              sendOtpMutation.mutate({ phoneNumber, retryCount: 1 })
            }>
            {timer.seconds === 60
              ? "Resend"
              : `Resend in ${timer.seconds} seconds`}
          </span>
        </p>
      )}
    </div>
  );
};

export default OTP;
