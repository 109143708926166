import { sendRequest } from "../sendRequest";

export const connectYtV2 = ({ data, token }) => {
  return sendRequest({
    method: "post",
    url: "/influencer/social-media-access/youtube-singular-connect",
    body: { ...data },
    options: {
      Authorization: `Bearer ${token}`,
    },
    isV2: true,
  });
};
