import React from "react";
import { Space, Row, Spin } from "antd";
import styles from "./../styles.module.css";
import { useQuery } from "react-query";
import queryString from "query-string";

import { getAppState, getState } from "handlers/me";
import { getYtOptions } from "../../Verify/FooterButton/utils/getYtOptions";

import { useAuthContext } from "../../../context/AuthContext";

import clsx from "clsx";

const Menu = ({ handleChangeModalVisibility }) => {
  const { signOutDevice, signedIn } = useAuthContext();

  const { queryKey, queryFunction } = getAppState({ connectRequestId: "" });

  const { queryKey: stateKey, queryFunction: stateFn } = getState();

  const { data: appStateData, isLoading: appStateLoading } = useQuery(
    queryKey,
    queryFunction,
    {
      enabled: signedIn,
    }
  );

  const { data, isLoading } = useQuery(stateKey, stateFn, {
    enabled: appStateData?.data?.sessionExpiredAccounts?.length > 0,
  });

  //ConnectionUrl's

  const getConnectionLink = (platform) => {
    const fbUrl = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=${process.env.REACT_APP_URL}v1&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}*instagram`;

    const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
      getYtOptions(true)
    )}&state=${data?.data}*youtube`;

    return window.open(platform === "instagram" ? fbUrl : ytUrl);
  };

  return (
    <div className={styles.menuWrapper}>
      {isLoading || appStateLoading ? (
        <Row justify={"center"}>
          <Spin />
        </Row>
      ) : (
        <>
          {" "}
          {appStateData?.data?.sessionExpiredAccounts.map((mediaItem) => (
            <section
              className={styles.accountIssueWrapper}
              onClick={() => getConnectionLink(mediaItem?.socialMediaPlatform)}>
              <Space size={20} align="center">
                <img
                  className={styles.accountProfile}
                  src={mediaItem?.profilePicUrl}
                  alt="profile"
                />
                <p className={styles.accountIssueDesc}>
                  @{mediaItem?.socialMediaName} has issue in connection, please
                  reconnect the {mediaItem?.socialMediaPlatform} profile.
                </p>
              </Space>
            </section>
          ))}
        </>
      )}

      <section className={styles.menuWrapperList}>
        <ul>
          <li
            onClick={() => handleChangeModalVisibility(true, "account")}
            role="button"
            className={styles.menuItem}>
            <div className={styles.flex}>
              <img src={"/icons/account.svg"} alt="account" />
              <p>Connected Accounts</p>
            </div>
            <img src={"/icons/chevron-right.svg"} alt="account" />
          </li>
          <li
            // onClick={() => setVisible(true)}
            role="button"
            className={clsx(styles.menuItem, styles.menuItemDisabled)}>
            <div className={styles.flex}>
              <img src={"/icons/invitation.svg"} alt="invitation" />
              <p>Invitations</p>
            </div>
            <img src={"/icons/chevron-right.svg"} alt="arrow" />
          </li>
          <li
            onClick={() => handleChangeModalVisibility(true, "community")}
            role="button"
            className={styles.menuItem}>
            <div className={styles.flex}>
              <img src={"/icons/cards-heart-outline.svg"} alt="heart" />
              <p>Manage my community</p>
            </div>
            <img src={"/icons/chevron-right.svg"} alt="arrow" />
          </li>
          <li
            onClick={() => handleChangeModalVisibility(true, "preference")}
            role="button"
            className={styles.menuItem}>
            <div className={styles.flex}>
              <img src={"/icons/tune-variant.svg"} alt="settings" />
              <p>Account Automations</p>
            </div>
            <img src={"/icons/chevron-right.svg"} alt="arrow" />
          </li>
          <li
            onClick={() => signOutDevice()}
            role="button"
            className={styles.menuItem}>
            <div className={styles.flex}>
              <img src={"/icons/logout-variant.svg"} alt="logout" />
              <p>Logout</p>
            </div>
            <img src={"/icons/chevron-right.svg"} alt="arrow" />
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Menu;
