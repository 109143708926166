import React from "react";
import { useQuery } from "react-query";
import { FaInstagram, FaPhone, FaYoutube } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Divider, Row } from "antd";

import { useAuthContext } from "context/AuthContext";
import { useStateContext } from "context/StateContext";
import { useGlobalLinkStateContext } from "context/GlobalLinkStateContext";

import { getPublicDetails } from "handlers/me";
import { getBrandDetails } from "handlers/public/getBrandDetails";

import styles from "./styles.module.css";

const PubLicDetails = () => {
  const { signedIn } = useAuthContext();
  const { connectRequestId } = useStateContext();
  const { brandId } = useGlobalLinkStateContext();

  const { queryKey, queryFunction } = getPublicDetails({ connectRequestId });

  const { data: publicData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(connectRequestId),
  });
  const { queryKey: brandKey, queryFunction: brandFn } = getBrandDetails({
    brandId,
  });

  const { data: brandData } = useQuery(brandKey, brandFn, {
    enabled: Boolean(brandId),
  });

  const phoneNumber = String(publicData?.data?.phoneNumber || "");
  const email = String(publicData?.data?.emailId || "");

  return (
    <div className={styles.container}>
      <section className={styles.detailContainer}>
        <img
          src={
            publicData?.data?.brand?.businessDetails?.logoUrl ||
            brandData?.data?.businessDetails?.logoUrl ||
            "https://fastly.picsum.photos/id/772/200/200.jpg?hmac=9euSj4JHTPr7uT5QWVmeNJ8JaqAXY8XmJnYfr_DfBJc"
          }
          alt="brandLogo"
          className={styles.profilePic}
        />
        <div className={styles.bName}>
          {publicData?.data?.brand?.businessDetails?.name ||
            brandData?.data?.businessDetails?.name ||
            ""}
        </div>
        <div className={styles.detailTitle}>Connect Request</div>
        {connectRequestId && (
          <>
            <p className={styles.name}>{publicData?.data?.fullName}</p>
            <div className={styles.space}>
              {publicData?.data?.platform === "instagram" ? (
                <FaInstagram size={13} color={"rgba(255, 255, 255, 0.70)"} />
              ) : (
                <FaYoutube size={13} color={"rgba(255, 255, 255, 0.70)"} />
              )}
              <span className={styles.listItem}>
                @{publicData?.data?.socialMediaName}
              </span>
            </div>
          </>
        )}

        {phoneNumber && (
          <div className={styles.space}>
            <FaPhone size={13} color={"rgba(255, 255, 255, 0.70)"} />
            {signedIn ? (
              <span className={styles.listItem}>{phoneNumber}</span>
            ) : (
              <span className={styles.listItem}>
                {phoneNumber?.slice(0, 4)}
                {Array(phoneNumber.length - 6)
                  .fill("*")
                  .join("")}
                {phoneNumber?.slice(-2)}
              </span>
            )}
          </div>
        )}
        {email && (
          <div className={styles.space}>
            <IoMdMail size={13} color={"rgba(255, 255, 255, 0.70)"} />
            {signedIn ? (
              <span className={styles.listItem}>{email}</span>
            ) : (
              <span className={styles.listItem}>
                {email?.slice(0, 2)}
                {Array(email.length - 8)
                  .fill("*")
                  .join("")}
                {email?.slice(-6)}
              </span>
            )}
          </div>
        )}
        <Divider style={{ marginBlock: 0 }} />
      </section>

      <div className={styles.descContainer}>
        <p className={styles.desc}>As a part of Brand’s community:</p>
        <p className={styles.desc}>
          <div style={{ display: "flex", gap: "2px", lineHeight: 1.5 }}>
            1.{" "}
            <div>
              Brands would be able to find your <br />
              profile on Cloutflow for all their future campaigns.
            </div>
          </div>
          <br />
          <div style={{ display: "flex", gap: "2px" }}>
            2.
            <div>View Insights of your Instagram profile.</div>
          </div>
        </p>
        <a className={styles.link} href="https://cloutflow.com/">
          🤔 How does this helps me?
        </a>
        <a className={styles.link} href="https://cloutflow.com/">
          🤔 Why do brand’s need this?
        </a>
        <p className={styles.subDesc}>
          You can withdraw from the community anytime from Cloutflow App.
        </p>
      </div>

      <Row justify={"center"}>
        <p className={styles.flex}>
          <span className={styles.footerTitle}>Secured by</span>
          <span>
            <img
              src="/img/logo.png"
              alt="logo"
              style={{ width: "15px", height: "9.985px" }}
            />
          </span>
          <span className={styles.cloutflow}>Cloutflow</span>
        </p>
      </Row>
    </div>
  );
};

export default PubLicDetails;
