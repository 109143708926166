import { sendRequest } from "../sendRequest";

export const getMe = () => {
  const queryKey = ["me"];
  const queryFunction = () =>
    sendRequest({
      method: "get",
      url: `/influencer/me?fields=all`,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
