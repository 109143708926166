import { sendRequest } from "../sendRequest";

export const sendOTP = ({ phoneNumber, retryCount }) => {
  return sendRequest({
    method: "post",
    url: `/influencer/login-otp`,
    body: {
      phoneNumber,
      retryCount,
    },
  }).then((res) => res.data);
};
