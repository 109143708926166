import React, { useState } from "react";

import clsx from "clsx";
import styles from "./styles.module.css";
import TitleMain from "components/Shared/Typography/TitleMain";
import OptionTile from "components/Shared/OptionTile";

const HomeMain = () => {
  return (
    <div className={clsx(styles.homeBody)}>
      <div className={clsx(styles.textBody)}>
        <TitleMain
          text="Manage your connected accounts & brand communities."
          isParagraph={true}
          start={12}
          end={30}
          customStyle={{ fontSize: "26px", fontWeight: "700" }}
        />
      </div>
      <OptionTile />
    </div>
  );
};

export default HomeMain;
