import { sendRequest } from "../sendRequest";

export const getPublicDetails = ({ connectRequestId }) => {
  const queryKey = ["getBrandDetails", connectRequestId];
  const queryFunction = () =>
    sendRequest({
      method: "GET",
      url: `/public/connect-request/by-id?connectRequestId=${connectRequestId}`,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
