import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";

import TestComp from "components/Test";
import Header from "../../components/GlobalConnect/Header";
import WelcomeScreen from "../../components/GlobalConnect/WelcomeScreen";
import LoginScreen from "../../components/GlobalConnect/LoginScreen";

import GlobalConnectContextProvider from "../../components/GlobalConnect/context/GlobalConnectContext";

const GlobalConnect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, signedIn, customSignIn } = useAuthContext();
  const [isFirst, setIsFirst] = useState(true);

  const [authLoading, setAuthLoading] = useState(
    searchParams.get("authCode") ? true : false
  );

  useEffect(() => {
    if (searchParams.get("authCode")) {
      customSignIn(searchParams.get("authCode"));
      setSearchParams((searchParams) => {
        searchParams.delete("authCode");
        return searchParams;
      });

      setAuthLoading(false);
    }
  }, [searchParams.get("authCode")]);

  if (loading || authLoading) {
    return (
      <Row justify={"center"}>
        <Spin />
      </Row>
    );
  }

  if (signedIn) {
    return <TestComp />;
  }

  return (
    <React.Fragment>
      <GlobalConnectContextProvider>
        <Header />
        {isFirst ? <WelcomeScreen setIsFirst={setIsFirst} /> : <LoginScreen />}
      </GlobalConnectContextProvider>
    </React.Fragment>
  );
};

export default GlobalConnect;
