import React from "react";
import { AnimatePresence } from "framer-motion";
import { Row } from "antd";

import { useGlobalConnectContextContext } from "../context/GlobalConnectContext";

import OptionContainer from "../../Shared/OptionContainer";
import PhoneInput from "./PhoneInput";
import OTPInput from "./OTPInput";
import FooterButton from "../FooterButton";

import styles from "../styles.module.css";

const LoginScreen = () => {
  const { buttonState } = useGlobalConnectContextContext();

  return (
    <div className={styles.loginContainer}>
      <div
        id="recaptcha-container"
        style={{ minHeight: "15px", margin: "-20px" }}
      />

      <AnimatePresence>
        <div key="test">
          <OptionContainer
            title={"1. Enter your phone number"}
            body={<PhoneInput />}
            status={buttonState === "login" ? "inProgress" : "completed"}
            containerStyles={{ borderRadius: "8px 8px 0px 0px" }}
          />

          <OptionContainer
            title={"2. Verify your phone number "}
            body={<OTPInput />}
            status={buttonState === "login" ? "notStarted" : "inProgress"}
            containerStyles={{ borderRadius: "0px 0px 8px 8px" }}
          />
          <div className={styles.footer}>
            <Row justify={"center"} style={{ marginBottom: "20px" }}>
              <p>
                <span className={styles.footerTitle}>Secured by</span>
                <span>
                  <img
                    src="/img/logo.png"
                    alt="logo"
                    style={{ width: "15px", height: "9.985px" }}
                  />
                </span>
                <span className={styles.cloutflow}>Cloutflow</span>
              </p>
            </Row>
            <FooterButton />
          </div>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default LoginScreen;
