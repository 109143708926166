import clsx from "clsx";
import { Spin, Row } from "antd";
import { Button, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./styles.module.css";

const Option = ({
  isSelected,
  handleClick,
  title,
  disabled,
  errorText = "",
}) => {
  return (
    <Button
      ghost
      disabled={disabled}
      className={styles.button}
      onClick={handleClick}
      block
      style={
        isSelected && {
          color: "var(--primaryColor)",
          borderColor: "var(--primaryColor)",
        }
      }
    >
      {title}
    </Button>
    // <div>
    //   <div
    //     onClick={disabled || isLoading ? () => {} : () => handleClick()}
    //     className={clsx(
    //       styles.container,
    //       { [styles.selected]: isSelected },
    //       { [styles.disabled]: disabled },
    //       { [styles.error]: Boolean(errorText) },
    //       { [styles.loading]: isLoading },
    //       className
    //     )}
    //     style={style}
    //   >
    //     {isLoading ? (
    //       <Row jus>
    //         <Spin
    //           size="small"
    //           style={{ color: "white", marginLeft: "12px" }}
    //           indicator={
    //             <LoadingOutlined
    //               style={{ fontSize: 24 }}
    //               spin
    //               color="#FFFFFF"
    //             />
    //           }
    //         />
    //       </Row>
    //     ) : (
    //       <> {title}</>
    //     )}
    //   </div>
    //   {errorText && <p className={styles.errorText}>{errorText}</p>}
    // </div>
  );
};
export default Option;
