import React from "react";
import styles from "./../styles.module.css";

const Footer = (props) => {
  const { version = 1 } = props;

  return (
    <footer>
      {version === 1 && (
        <p className={styles.footerText}>
          Cloutflow connect is a product of{" "}
          <span className={styles.footerTextBold}>@Cloutflow</span>.
        </p>
      )}
      {version === 2 && (
        <p className={styles.footerText}>
          All your accounts are securely connected, we take read-only
          permissions. <span className={styles.footerTextLink}>Know more</span>
        </p>
      )}
      {version === 3 && (
        <p className={styles.footerText}>
          You can withdraw from community anytime.{" "}
          <span className={styles.footerTextLink}>Know more</span>
        </p>
      )}
    </footer>
  );
};

export default Footer;
