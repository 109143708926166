import React, { useState } from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import NoInstaConnected from "./NoInstaConnected";
import NoInstaFbPage from "./NoInstaFbPage";

import styles from "./updatedStyles.module.css";

const DifferentErrorsDrawer = ({ handleBack, visible, accounts }) => {
  const [noInstaConnectedDrawer, setNoInstaConnectedDrawer] = useState(false);
  const [noInstaFbPageDrawer, setNoInstaFbPageDrawer] = useState(false);

  const getListItem = (account) => {
    if (account.reason === "existingOtherAccountHasIgUser") {
      return (
        <>
          Instagram Page{" "}
          <span className={styles.link}>@{account?.igUserName}</span>is already
          connected by another user with phone number
          <span>
            {account?.phoneNumberLinked?.slice(0, 4)}
            {Array(account?.phoneNumberLinked.length - 6)
              .fill("*")
              .join("")}
            {account?.phoneNumberLinked?.slice(-2)}
          </span>
        </>
      );
    }
    if (!account?.isPageLinkedToInsta) {
      return (
        <>
          Facebook page <span className={styles.link}>{account?.pageName}</span>{" "}
          is not connected with any Instagram account.{" "}
          <span
            className={styles.bold}
            onClick={() => setNoInstaFbPageDrawer(true)}>
            Know more
          </span>
        </>
      );
    }
    if (account?.isPageLinkedToInsta && !account?.isInstaPageSelectedWithPage) {
      return (
        <>
          Facebook page <span className={styles.link}>{account?.pageName}</span>{" "}
          is connected with Instagram account{" "}
          <span className={styles.link}>@{account?.igUserName}</span>, but you
          missed selecting the Instagram account while connecting.
          <span
            className={styles.bold}
            onClick={() => setNoInstaConnectedDrawer(true)}>
            Know more
          </span>
        </>
      );
    }
  };

  return (
    <>
      <CustomDrawer visible={visible} handleClose={handleBack}>
        <main>
          <p className={styles.heading} style={{ marginBottom: "20px" }}>
            Some pages that you were tried to <br /> connect are facing issues:
          </p>
          <ul className={styles?.accountList}>
            {accounts
              ?.filter((account) => account?.isErrorConnectingOnApp)
              .map((item) => {
                return (
                  <li className={styles.subHeading}>{getListItem(item)}</li>
                );
              })}
          </ul>
        </main>
        <NoInstaConnected
          visible={noInstaConnectedDrawer}
          handleBack={() => {
            setNoInstaConnectedDrawer(false);
          }}
          accounts={accounts}
        />
        <NoInstaFbPage
          visible={noInstaFbPageDrawer}
          handleBack={() => {
            setNoInstaFbPageDrawer(false);
          }}
          accounts={accounts}
        />
      </CustomDrawer>
    </>
  );
};

export default DifferentErrorsDrawer;
