import React, { createContext, useState, useRef, useEffect } from "react";

const StateContext = createContext(null);

export default function StateContextProvider({ children }) {
  const [buttonState, setButtonState] = useState("start");

  const updateButtonState = (newValue) => {
    setButtonState(newValue);
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") || "";
  const fbState =
    (urlParams.get("state") || "").split("*").at(1) === "instagram"
      ? (urlParams.get("state") || "").split("*").at(0)
      : undefined;
  const err = urlParams.get("error") || "";
  const ytState =
    (urlParams.get("state") || "").split("*").at(1) === "youtube"
      ? (urlParams.get("state") || "").split("*").at(0)
      : undefined;
  const connectRequestId =
    (urlParams.get("state") || "").split("*").at(-1) ||
    urlParams.get("connectRequestId") ||
    "";
  useEffect(() => {
    console.log("hello", urlParams.get("connectRequestId"));
  }, [urlParams.get("connectRequestId")]);

  const brandId = urlParams.get("brandId");

  const [otp, setOtp] = useState("");
  const [globalDetails, setGlobalDetails] = useState({
    phoneNumber: "",
    name: "",
    platform: "instagram",
  });
  const [uid, setUid] = useState("");
  const [userIgs, setUserIgs] = useState([]);
  const [userYts, setUserYts] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [selectedYt, setSelectedYt] = useState(null);
  const [selectedIg, setSelectedIg] = useState(null);
  const [state, setState] = useState({
    otp: {
      opened: false,
      completed: false,
    },
    social: {
      opened: false,
      completed: false,
    },
    community: {
      opened: false,
      completed: false,
    },
  });

  const contextValue = {
    buttonState,
    updateButtonState,
    otp,
    setOtp,
    uid,
    setUid,
    code,
    fbState,
    connectRequestId,
    userIgs,
    setUserIgs,
    accessToken,
    setAccessToken,
    selectedIg,
    setSelectedIg,
    userYts,
    setUserYts,
    selectedYt,
    setSelectedYt,
    state,
    setState,
    ytState,
    err,
    brandId,
    globalDetails,
    setGlobalDetails,
    // firstChange,
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => {
  const context = React.useContext(StateContext);
  if (!context) {
    throw new Error(
      "useStateContext must be used within a StateContextProvider"
    );
  }
  return context;
};
