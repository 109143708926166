import React from "react";

import MainButton from "components/Shared/Button/MainButton";

import styles from "../styles.module.css";

const WelcomeScreen = ({ setIsFirst }) => {
  return (
    <div className={styles.welcomeContainer}>
      <h2
        className={styles.communityHeader}
        style={{
          marginTop: 0,
        }}>
        🔒Securely share your <span>social data</span> via social media login
      </h2>
      <h2
        className={styles.communityHeader}
        style={{
          marginTop: 40,
          marginBottom: 80,
        }}>
        Say Goodbye to <span>Screenshots</span>
      </h2>
      <MainButton text={"Continue"} onClick={() => setIsFirst(false)} />
    </div>
  );
};

export default WelcomeScreen;
