import React, { useState, useEffect } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  useQuery,
} from "react-query";
import queryString from "query-string";
import { Modal, Row, Spin, message, Button, Tag } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { clsx } from "clsx";

import styles from "../styles.module.css";

import ModalWrapper from "../ModalWrapper";
import PermissionErrorDrawer from "components/PlatformConnect/FacebookErrorDrawer/PermissionsError";
import InternalError from "components/PlatformConnect/FacebookErrorDrawer/InternalError";
import AlreadyLinked from "components/PlatformConnect/FacebookErrorDrawer/AlreadyLinked";
import DifferentErrorsDrawer from "components/PlatformConnect/FacebookErrorDrawer/DifferentErrors";
import NoFbPageDrawer from "components/PlatformConnect/FacebookErrorDrawer/NoFbPage";
import AddAccountModal from "./AddAccountModal";

import { getConnectedAccounts, handleDisconnect } from "handlers/accounts";
import { connectIgV2, connectYtV2 } from "handlers/connectSocial";
import { useAuthContext } from "context/AuthContext";
import { getAppState, getState } from "handlers/me";

import { getYtOptions } from "components/Verify/FooterButton/utils/getYtOptions";

import { getIgPermissions } from "Utility/getIgPermissions";
import { auth } from "../../../Firebase";

const ConnectedAccounts = ({ fullClose }) => {
  const { signedIn } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState(undefined);

  const isTestUser = auth?.currentUser?.phoneNumber === "+911234567899";

  const { queryKey: stateQueryKey } = getAppState({ connectRequestId: "" });
  const { queryKey: stateKey, queryFunction: stateFn } = getState();

  const urlQueryString = window.location.search;
  const urlParams = new URLSearchParams(urlQueryString);

  const connectIgMutation = useMutation(connectIgV2);
  const connectYtMutation = useMutation(connectYtV2);

  const revokedPlatform = (urlParams.get("state") || "").includes("instagram")
    ? "instagram"
    : (urlParams.get("state") || "").includes("youtube")
    ? "youtube"
    : "";

  const fbState =
    revokedPlatform === "instagram"
      ? (urlParams.get("state") || "").split("*").at(0)
      : undefined;

  const askMessingPermission = String(urlParams.get("state") || "").includes(
    "advanced"
  );

  const ytState =
    revokedPlatform === "youtube"
      ? (urlParams.get("state") || "").split("*").at(0)
      : undefined;

  const code = urlParams.get("code") || "";

  const handleSelect = (acc) => {
    setSelectedAcc(acc);
    setVisible(true);
  };
  const { data: stateData, isLoading: stateLoading } = useQuery(
    stateKey,
    stateFn,
    {
      enabled: visible,
    }
  );

  const { queryKey, queryFunction } = getConnectedAccounts({
    limit: 20,
    connectionStatus: ["Connected", "Session-Expired"],
  });

  const isQueryEnabled = () => {
    if (revokedPlatform === "instagram")
      return !connectIgMutation.isLoading && signedIn;
    else if (revokedPlatform === "youtube")
      return !connectYtMutation.isLoading && signedIn;
    return signedIn;
  };

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.community?.length ? lastPage.data.pageNo + 1 : null,
      enabled: isQueryEnabled(),
    }
  );

  const queryClient = useQueryClient();

  const isNullOrEmpty = (obj) => {
    return (
      obj === null || (typeof obj === "object" && Object.keys(obj).length === 0)
    );
  };

  const handleDisconnectMutation = useMutation(handleDisconnect, {
    onSuccess: (resData) => {
      const data = resData?.data?.data;
      if (data && !data?.canDisconnect) {
        if (!isNullOrEmpty(data?.inBrandCommunity)) {
          Modal.error({
            title: "Can't Disconnect Your Profile",
            content: (
              <div>
                <p>
                  You are in a Community, You have to leave Community to
                  disconnect your Account
                </p>
              </div>
            ),
            onOk() {},
          });
        } else {
          Modal.error({
            title: "Can't Disconnect Your Profile",
            content: (
              <div>
                <p>You Are already in an Ongoing Campaign</p>
              </div>
            ),
            onOk() {},
          });
        }
      } else {
        queryClient.invalidateQueries(queryKey);
        // fullClose(false);
        setSelectedAcc(undefined);
        setVisible(false);
        message.success("Account Disconnected Successfully!");
      }
    },
    onError: (err) => {
      console.log(err);

      message.error("Something went wrong while disconnecting account");
    },
  });

  const [userIgs, setUserIgs] = useState([]);
  const [userYts, setUserYts] = useState([]);
  const [permissionErrorDrawer, setPermissionErrorDrawer] = useState(false);
  const [internalErrorDrawer, setInternalErrorDrawer] = useState(false);
  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [noFbDrawerVisible, setNoFbDrawerVisible] = useState(false);

  const [addAccountModalVisible, setAddAccountModalVisible] = useState(false);

  useEffect(() => {
    if (code && fbState) {
      return connectIgMutation.mutate(
        {
          data: {
            code: code,
            redirectUri: `${process.env.REACT_APP_URL}v1`,
            isPermissionForMessaging: askMessingPermission,
          },
          token: fbState,
        },
        {
          onSuccess: (res) => {
            const resData = res?.data?.data;
            if (res.status === 200) {
              setUserIgs(resData?.connectAccounts);
              queryClient.invalidateQueries(stateQueryKey);
              if (resData?.connectAccounts?.length === 0) {
                return setNoFbDrawerVisible(true);
              }
              if (
                resData?.connectAccounts?.some(
                  (account) => account?.isErrorConnectingOnApp
                )
              ) {
                return setShowErrorMessage(true);
              }
            }
          },
        }
      );
    } else if (code && ytState) {
      return connectYtMutation.mutate(
        {
          data: {
            code: code,
            redirectUri: `${process.env.REACT_APP_URL}v1`,
          },
          token: ytState,
        },
        {
          onSuccess: (res) => {
            const resData = res?.data?.data;
            if (res.status === 200) {
              if (resData?.isScopeNotFound) {
                setShowErrorMessage(true);
                return setPermissionErrorDrawer(true);
              }
              if (resData?.connectAccounts?.length === 0) {
                setShowErrorMessage(true);
                return setInternalErrorDrawer(true);
              }
              setUserYts(resData?.connectAccounts);
              queryClient.invalidateQueries(stateQueryKey);
              if (
                resData?.connectAccounts?.some(
                  (account) =>
                    account.reason === "existingOtherAccountHasYtUser"
                )
              ) {
                setShowErrorMessage(true);
                return setAlreadyLinked(true);
              }
            }
          },
        }
      );
    } else {
      setUserIgs([]);
      setUserYts([]);
    }
  }, [code, fbState, ytState]);

  if (isLoading || connectIgMutation.isLoading || connectYtV2.isLoading) {
    return (
      <Row justify={"center"}>
        <Spin />
      </Row>
    );
  }

  //ConnectionUrl's

  const getConnectionLink = (platform, askPermission) => {
    const igPermissions = getIgPermissions(askPermission);
    const fbUrl = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${
      process.env.REACT_APP_FB_KEY
    }&redirect_uri=${
      process.env.REACT_APP_URL
    }v1&scope=${igPermissions}&state=${stateData?.data}*instagram*${
      askPermission ? "advanced" : "basic"
    }`;

    const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
      getYtOptions(true)
    )}&state=${stateData?.data}*youtube`;

    window.open(platform === "instagram" ? fbUrl : ytUrl);
    setSelectedAcc(null);
    setVisible(false);
  };

  // console.log(selectedAcc, stateLoading);

  return (
    <div style={{ width: "100%" }}>
      <Row justify={"space-between"} align={"middle"}>
        <h6 className={styles.emptyHeader}>Connected accounts</h6>
        <Button type="primary" onClick={() => setAddAccountModalVisible(true)}>
          Add Account
        </Button>
      </Row>
      {data?.pages?.length > 0 && data?.pages[0]?.data?.length > 0 ? (
        <div className={styles.menuWrapper} style={{ margin: 0 }}>
          <InfiniteScroll
            loadMore={() => fetchNextPage()}
            // style={{ display: "grid", gap: 10, alignItems: "flex-start" }}
            hasMore={hasNextPage}
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            useWindow={false}>
            <ul>
              {data?.pages.map((item) => {
                return item.data
                  ?.filter((accItem) => accItem.dataProvider === "default")
                  .map((account, key) => {
                    return (
                      <React.Fragment key={key}>
                        <li
                          onClick={() => handleSelect(account)}
                          role="button"
                          className={styles.menuItem}>
                          <div className={styles.flex}>
                            <img
                              src={
                                account?.instagram?.user.profilePicUrl ??
                                account?.youtube?.user.profilePicUrl ??
                                ""
                              }
                              alt="account"
                              style={{ borderRadius: "50%", width: 42 }}
                            />
                            <div>
                              <p className={styles.username}>
                                @{account?.socialMediaName}
                              </p>

                              {account.connectionStatus ===
                                "Session-Expired" && (
                                <Tag
                                  color="volcano"
                                  style={{
                                    padding: "2px",
                                    margin: "0.5em 0",
                                    borderRadius: 8,
                                  }}>
                                  Revoked
                                </Tag>
                              )}
                            </div>
                          </div>
                          <img src={"/icons/chevron-right.svg"} alt="chevron" />
                        </li>
                      </React.Fragment>
                    );
                  });
              })}
            </ul>
          </InfiniteScroll>
        </div>
      ) : (
        <p className={styles.emptyText}>
          You don't have any connected accounts
        </p>
      )}
      {showErrorMessage && (
        <div className={styles.connectDesc} style={{ marginTop: 20 }}>
          Some pages you were trying to connect failed.{" "}
          <span
            className={styles.bold}
            onClick={() => setErrorModalVisible(true)}>
            See failed pages
          </span>
        </div>
      )}
      {/* Signe Account Remove/DisConnect */}
      <ModalWrapper setVisible={setVisible} visible={visible}>
        <div className={styles.acc_header}>
          <div className={styles.acc_image}>
            <img
              src={
                selectedAcc?.instagram?.user.profilePicUrl ??
                selectedAcc?.youtube?.user.profilePicUrl ??
                ""
              }
              alt="invitation"
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                objectFit: "cover",
              }}
            />
          </div>
          <div>
            <p className={styles.acc_name}>{selectedAcc?.socialMediaName}</p>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className={styles.menuWrapper} style={{ margin: 0 }}>
            <ul>
              <li
                onClick={() => {
                  if (
                    stateLoading ||
                    selectedAcc?.connectionStatus !== "Session-Expired"
                  )
                    return;
                  getConnectionLink(selectedAcc?.socialMediaPlatform, false);
                }}
                role="button"
                className={clsx(styles.menuItem, {
                  [styles.menuItemDisabled]:
                    selectedAcc?.connectionStatus !== "Session-Expired" ||
                    stateLoading,
                })}
                aria-disabled>
                <div className={styles.flex}>
                  <p className={styles.username}>Reconnect Account</p>
                </div>
                <img src={"/icons/chevron-right.svg"} alt="account" />
              </li>
              {selectedAcc?.socialMediaPlatform === "instagram" &&
                isTestUser && (
                  <li
                    onClick={() => {
                      if (
                        stateLoading ||
                        selectedAcc?.canSendIgMessageStatus === "Success"
                      )
                        return;
                      getConnectionLink(selectedAcc?.socialMediaPlatform, true);
                    }}
                    role="button"
                    className={clsx(styles.menuItem, {
                      [styles.menuItemDisabled]:
                        selectedAcc?.canSendIgMessageStatus === "Success" ||
                        stateLoading,
                    })}
                    aria-disabled>
                    <div className={styles.flex}>
                      <p className={styles.username}>
                        Give Messaging Permission
                      </p>
                    </div>
                    <img src={"/icons/chevron-right.svg"} alt="account" />
                  </li>
                )}

              <li
                onClick={() =>
                  handleDisconnectMutation.isLoading
                    ? {}
                    : handleDisconnectMutation.mutate(selectedAcc?._id)
                }
                role="button"
                className={clsx(
                  styles.menuItem,
                  handleDisconnectMutation.isLoading
                    ? styles.menuItemDisabled
                    : ""
                )}>
                <div className={styles.flex}>
                  <p className={styles.username}>
                    {handleDisconnectMutation.isLoading ? (
                      <Spin />
                    ) : (
                      "Disconnect Profile"
                    )}
                  </p>
                </div>
                <img src={"/icons/chevron-right.svg"} alt="arrow" />
              </li>
            </ul>
          </div>
        </div>
      </ModalWrapper>
      <AddAccountModal
        visible={addAccountModalVisible}
        handleClose={() => setAddAccountModalVisible(false)}
      />

      <PermissionErrorDrawer
        visible={permissionErrorDrawer && errorModalVisible}
        handleBack={() => {
          // setSearchParams(() => ({
          //   connectRequestId: searchParams.get("connectRequestId") || "",
          // }));
          setPermissionErrorDrawer(false);
          setErrorModalVisible(false);
        }}
      />
      <InternalError
        visible={internalErrorDrawer && errorModalVisible}
        handleBack={() => {
          // setSearchParams(() => ({
          //   connectRequestId: searchParams.get("connectRequestId") || "",
          // }));
          setInternalErrorDrawer(false);
          setErrorModalVisible(false);
        }}
      />
      <AlreadyLinked
        visible={alreadyLinked && errorModalVisible}
        handleBack={() => {
          setAlreadyLinked(false);
          setErrorModalVisible(false);
        }}
        connectedAccounts={revokedPlatform === "instagram" ? userIgs : userYts}
        platform={revokedPlatform}
      />
      <DifferentErrorsDrawer
        visible={errorModalVisible}
        handleBack={() => setErrorModalVisible(false)}
        accounts={userIgs}
      />
      <NoFbPageDrawer
        visible={noFbDrawerVisible}
        handleBack={() => {
          setNoFbDrawerVisible(false);
        }}
      />
    </div>
  );
};

export default ConnectedAccounts;
