import React, { useState } from "react";
import { message } from "antd";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../../Firebase";
import { useNavigate } from "react-router-dom";

import MainButton from "components/Shared/Button/MainButton";

// import { acceptInvite, getAppState } from "handlers/me";
import { getConnectRequestId } from "handlers/me";
import { getBrandDetails } from "../../../handlers/public/getBrandDetails";
import { useGlobalLinkStateContext } from "context/GlobalLinkStateContext";
import { useAuthContext } from "context/AuthContext";

const FooterButtonBrand = () => {
  const {
    buttonState,
    updateButtonState,
    brandId,
    globalDetails,
    setGlobalDetails,
    uid,
    setUid,
  } = useGlobalLinkStateContext();

  const queryClient = useQueryClient();
  const { sendOtpFirebase, verifyOtpFirebase, firstRender } = useAuthContext();

  const { queryKey: brandKey, queryFunction: brandFn } = getBrandDetails({
    brandId,
  });

  const { data } = useQuery(brandKey, brandFn, { enabled: Boolean(brandId) });

  const navigate = useNavigate();

  const [firebaseOtpLoading, setFirebaseOtpLoading] = useState(false);

  const getConnectRequestIdMutation = useMutation(getConnectRequestId, {
    onSuccess: (response) => {
      firstRender.current = true;

      navigate(`/?connectRequestId=${response?.data?.data?.connectRequestId}`);
      navigate(0);
      queryClient.invalidateQueries();

      //   setFirebaseOtpLoading(false);
    },
    onError: (err) => {
      console.log(err);
      message.error("Something went wrong while processing request");
    },
  });

  const handleSendOtp = async () => {
    try {
      // auth.settings.appVerificationDisabledForTesting = true;

      if (
        globalDetails.phoneNumber.length < 7 ||
        globalDetails.phoneNumber.length > 12
      ) {
        return message.error("Invalid Number");
      }

      const phone = globalDetails?.countryCode + globalDetails?.phoneNumber;
      setFirebaseOtpLoading(true);
      let appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      sendOtpFirebase(phone, appVerifier)
        .then((result) => {
          if (result?.verificationId) {
            updateButtonState("verify");
            setUid(result?.verificationId);
          } else {
            message.error(
              "We detected a suspicious login, Please refresh the app"
            );
          }
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        })
        .catch(async (err) => {
          message.error(err?.code);
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        });
    } catch (err) {
      // backend Api to throw error
      console.log("err", err);
      message.error(
        err?.code + "We detected a suspicious login, Please refresh the app"
      );

      setFirebaseOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setFirebaseOtpLoading(true);
    await verifyOtpFirebase(globalDetails?.otp, uid)
      .catch(async (err) => {
        setFirebaseOtpLoading(false);
        return message.error(err?.code + " ,Please Retry");
      })
      .then(() => {
        // getConnectRequestIdMutation.mutate({
        //   brandId,
        //   fullName: globalDetails.name,
        //   platform: globalDetails.platform,
        // });
        updateButtonState("platformSelect");
      });
  };

  const getButtonProps = () => {
    // start->otp->verify->platformSelect
    switch (buttonState) {
      case "start":
        return {
          text: "Join the Community",
          onClick: () => {
            updateButtonState("otp");
          },
        };

      case "otp":
        return {
          text: "Send OTP",
          disabled: !Boolean(globalDetails.phoneNumber),
          onClick: () => {
            if (globalDetails.phoneNumber) {
              handleSendOtp();
            }
          },
          loading: firebaseOtpLoading,
        };
      case "verify":
        return {
          text: "Verify OTP",
          onClick: () => {
            handleVerifyOtp();
          },
          loading: firebaseOtpLoading,
          disabled: !Boolean(globalDetails.otp),
        };

      case "platformSelect":
        return {
          text: "Select Platform",
          onClick: () => {
            getConnectRequestIdMutation.mutate({
              brandId: data?.data?._id,
              platform: globalDetails.platform,
              fullName: "",
            });
          },
          loading: getConnectRequestIdMutation.isLoading,
          disabled: !Boolean(globalDetails.platform),
        };

      default:
        return { text: "", onClick: () => {} };
    }
  };

  return (
    <MainButton
      type="primary"
      text={getButtonProps()?.text}
      onClick={() => getButtonProps()?.onClick()}
      isLoading={getButtonProps()?.loading || false}
      disabled={getButtonProps().disabled || false}
    />
  );
};

export default FooterButtonBrand;
