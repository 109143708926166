import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import ModalWrapper from "../GlobalConnect/ModalWrapper";

import Header from "../GlobalConnect/Header";
import Footer from "../GlobalConnect/Footer";
import Menu from "../GlobalConnect/Menu";
import Community from "../GlobalConnect/Community";
import Preferences from "../GlobalConnect/Preferences";
import ConnectedAccounts from "../GlobalConnect/ConnectedAccounts";
import EmptyScreen from "../GlobalConnect/EmptyScreen";

import { getAppState } from "handlers/me";

const TestComp = () => {
  const [accountVisible, setAccountVisible] = useState(false);
  const [communitiesVisible, setCommunitiesVisible] = useState(false);
  const [preferenceVisible, setPreferenceVisible] = useState(false);
  const [_, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { queryKey } = getAppState({ connectRequestId: "" });

  const firstRender = useRef(true);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") || "";

  useEffect(() => {
    if (firstRender.current && code) {
      setAccountVisible(true);
      firstRender.current = false;
    }
  }, [firstRender.current, code]);

  const handleChangeModalVisibility = (visibility, type) => {
    switch (type) {
      case "account":
        setAccountVisible(visibility);
        break;
      case "community":
        setCommunitiesVisible(visibility);
        break;
      case "preference":
        setPreferenceVisible(visibility);
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <Header isLoggedIn />
      <Menu handleChangeModalVisibility={handleChangeModalVisibility} />
      <Footer />
      <ModalWrapper
        visible={accountVisible}
        setVisible={(visible) => {
          setAccountVisible(visible);
          setSearchParams(() => ({}));
          queryClient.invalidateQueries(queryKey);
        }}>
        <ConnectedAccounts fullClose={setAccountVisible} />
      </ModalWrapper>
      <ModalWrapper
        visible={communitiesVisible}
        setVisible={setCommunitiesVisible}>
        <Community fullClose={setCommunitiesVisible} />
      </ModalWrapper>
      <ModalWrapper
        visible={preferenceVisible}
        setVisible={setPreferenceVisible}>
        {/* <EmptyScreen /> */}
        <Preferences />
        {/* <ConnectedAccounts fullClose={setVisible} /> */}
        {/* <Community fullClose={setVisible} /> */}
      </ModalWrapper>
    </React.Fragment>
  );
};

export default TestComp;
