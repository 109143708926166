import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "react-query";

import { FaInstagram, FaPhone, FaYoutube } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";

import { getPublicDetails } from "handlers/me";
import { getBrandDetails } from "handlers/public/getBrandDetails";

import styles from "./styles.module.css";

const panelVariants = {
  visible: {
    height: "initial",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const RequestHeader = ({
  ShowFull = false,
  connectRequestId = "",
  brandId = "",
}) => {
  const { queryKey, queryFunction } = getPublicDetails({ connectRequestId });

  const { data: publicData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(connectRequestId),
  });
  const { queryKey: brandKey, queryFunction: brandFn } = getBrandDetails({
    brandId,
  });

  const { data: brandData } = useQuery(brandKey, brandFn, {
    enabled: Boolean(brandId),
  });

  const phoneNumber = String(publicData?.data?.phoneNumber || "");
  const email = String(publicData?.data?.emailId || "");

  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <div className={styles.container}>
      <img
        src={
          publicData?.data?.brand?.businessDetails?.logoUrl ||
          brandData?.data?.businessDetails?.logoUrl ||
          "https://fastly.picsum.photos/id/772/200/200.jpg?hmac=9euSj4JHTPr7uT5QWVmeNJ8JaqAXY8XmJnYfr_DfBJc"
        }
        alt="brandLogo"
        className={styles.profilePic}
      />
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row justify={"center"}>
            <div className={styles.bName}>
              {publicData?.data?.brand?.businessDetails?.name ||
                brandData?.data?.businessDetails?.name ||
                ""}
            </div>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"center"}>
            <div className={styles.title}>Connect Request</div>
          </Row>
        </Col>
      </Row>
      {ShowFull && (
        <>
          <Divider style={{ marginBlock: 10 }} />
          <Row justify={"center"}>
            <div
              className={styles.link}
              onClick={() =>
                setDetailsVisible((detailsVisible) => !detailsVisible)
              }>
              <p>{detailsVisible ? "Hide" : "Show"} details</p>
              {detailsVisible ? (
                <PiCaretUpBold size={14} color={"var(--primaryColor)"} />
              ) : (
                <PiCaretDownBold size={14} color={"var(--primaryColor)"} />
              )}
            </div>
          </Row>
          <AnimatePresence>
            {detailsVisible && (
              <motion.section
                initial="hidden"
                animate="visible"
                exit="hidden"
                key="details"
                variants={panelVariants}>
                <div className={styles.grid}>
                  {connectRequestId && (
                    <>
                      <p className={styles.heading}>Connect Request for</p>
                      <p className={styles.name}>
                        {publicData?.data?.fullName}
                      </p>
                      <div className={styles.space}>
                        {publicData?.data?.platform === "instagram" ? (
                          <FaInstagram
                            size={13}
                            color={"rgba(255, 255, 255, 0.70)"}
                          />
                        ) : (
                          <FaYoutube
                            size={13}
                            color={"rgba(255, 255, 255, 0.70)"}
                          />
                        )}

                        <span className={styles.listItem}>
                          @{publicData?.data?.socialMediaName}
                        </span>
                      </div>
                    </>
                  )}
                  {phoneNumber && (
                    <div className={styles.space}>
                      <FaPhone size={13} color={"rgba(255, 255, 255, 0.70)"} />
                      <span className={styles.listItem}>
                        {phoneNumber?.slice(0, 4)}
                        {Array(phoneNumber.length - 6)
                          .fill("*")
                          .join("")}
                        {phoneNumber?.slice(-2)}
                      </span>
                    </div>
                  )}
                  {email && (
                    <div className={styles.space}>
                      <IoMdMail size={13} color={"rgba(255, 255, 255, 0.70)"} />
                      <span className={styles.listItem}>
                        {email?.slice(0, 2)}
                        {Array(email.length - 8)
                          .fill("*")
                          .join("")}
                        {email?.slice(-6)}
                      </span>
                    </div>
                  )}
                </div>
              </motion.section>
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );
};

export default RequestHeader;
