import { sendRequest } from "../sendRequest";

export const getState = () => {
  const queryKey = ["state"];
  const queryFunction = () =>
    sendRequest({ method: "put", url: `/influencer/social-media-access/state` })
      .then((res) => res.data)
      // .catch((err) => console.log(err));
  return { queryKey, queryFunction };
};
