import React from "react";
import { capitalizeFirstLetter } from "../../../Utility/capitalizeFirstLetter";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const AlreadyLinked = ({
  handleBack,
  visible,
  connectedAccounts,
  platform,
}) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main>
        <p className={styles.heading} style={{ marginBottom: "40px" }}>
          {capitalizeFirstLetter(platform)} account is already in use by another
          user
        </p>
        <div style={{ display: "grid", gap: "20px" }}>
          {connectedAccounts
            ?.filter(
              (account) =>
                account.reason === "existingOtherAccountHasIgUser" ||
                account.reason === "existingOtherAccountHasYtUser"
            )
            .map((pageItem) => {
              return (
                <p
                  className={styles.subHeading}
                  style={{ marginBottom: "20px" }}>
                  @{pageItem?.igUserName || pageItem?.channelName} is already is
                  use by another user by the phone number
                  <span>
                    {pageItem?.phoneNumberLinked?.slice(0, 4)}
                    {Array(pageItem?.phoneNumberLinked.length - 6)
                      .fill("*")
                      .join("")}
                    {pageItem?.phoneNumberLinked?.slice(-2)}
                  </span>
                </p>
              );
            })}
        </div>
      </main>
    </CustomDrawer>
  );
};

export default AlreadyLinked;
