import React, { useState } from "react";
import { Spin, Row, message } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { AnimatePresence, motion } from "framer-motion";

import { Input } from "antd";

import EmptyScreen from "../EmptyScreen";

import styles from "./../styles.module.css";
import { useAuthContext } from "context/AuthContext";
import { capitalizeFirstLetter } from "Utility/capitalizeFirstLetter";

import MainButton from "../../Shared/Button/MainButton";

import { BsInstagram, BsYoutube } from "react-icons/bs";

import { getUserCommunities, removeUserCommunity } from "handlers/community/";

import ModalWrapper from "../ModalWrapper";

const { TextArea } = Input;

const Community = ({ fullClose }) => {
  const { signedIn } = useAuthContext();
  const [singleCommunityProps, setSingleCommunityProps] = useState({
    visible: false,
    selectedCommunity: null,
  });

  const queryClient = useQueryClient();

  const removeUserMutation = useMutation(removeUserCommunity, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setSingleCommunityProps({ visible: false, selectedCommunity: null });
      setValue("");
      setShow(false);
      // fullClose(false);
      message.success("Left Community");
    },
    onError: (err) => {
      console.log(err);
      message.error("Something went wrong while leaving Community");
    },
  });

  const { queryKey, queryFunction } = getUserCommunities({ limit: 20 });

  const {
    data: userCommunitiesData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(queryKey, queryFunction, {
    getNextPageParam: (lastPage) =>
      lastPage.data.community?.length ? lastPage.data.pageNo + 1 : null,
    enabled: Boolean(signedIn),
  });

  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  if (isLoading) {
    return (
      <Row justify={"center"}>
        <Spin />
      </Row>
    );
  }

  const onChange = (e) => setValue(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(value);
  };

  return (
    <div style={{ width: "100%" }}>
      {userCommunitiesData?.pages?.length > 0 &&
      userCommunitiesData?.pages[0]?.data?.community.length > 0 ? (
        <>
          <h2
            className={styles.communityHeader}
            style={{
              marginTop: 0,
            }}>
            Manage your <span>brand community</span>
          </h2>

          <h3 className={styles.emptyHeader}>Communities you are a part of</h3>

          <div className={styles.menuWrapper} style={{ margin: 0 }}>
            <InfiniteScroll
              loadMore={() => fetchNextPage()}
              // style={{ display: "grid", gap: 10, alignItems: "flex-start" }}
              hasMore={hasNextPage}
              loader={
                <Row justify="center">
                  <Spin />
                </Row>
              }
              useWindow={false}>
              <ul style={{ marginLeft: 0 }}>
                {userCommunitiesData?.pages?.map((pageItem) =>
                  pageItem.data?.community?.map((dataItem) => {
                    return (
                      <li
                        onClick={() =>
                          setSingleCommunityProps({
                            visible: true,
                            selectedCommunity: dataItem,
                          })
                        }
                        role="button"
                        className={styles.menuItem}>
                        <div className={styles.flex}>
                          <img
                            src={dataItem?.brand?.businessDetails?.logoUrl}
                            alt="account"
                            style={{
                              borderRadius: "50%",
                              width: 50,
                              height: 50,
                              objectFit: "cover",
                            }}
                          />
                          <div>
                            <p className={styles.username}>
                              {dataItem?.brand?.businessDetails?.name || ""}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                marginTop: 3,
                              }}>
                              {dataItem?.platform === "youtube" ? (
                                <BsYoutube size={13} color="#FFF" />
                              ) : (
                                <BsInstagram size={13} color="#FFF" />
                              )}
                              <p className={styles.platform}>
                                @{dataItem?.socialMediaName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <img src={"/icons/chevron-right.svg"} alt="account" />
                      </li>
                    );
                    // <CardRow data={dataItem} />
                  })
                )}
              </ul>
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <EmptyScreen />
      )}

      <ModalWrapper
        visible={singleCommunityProps.visible}
        setVisible={(value) =>
          setSingleCommunityProps({ selectedCommunity: null, visible: value })
        }>
        <h2
          className={styles.communityHeader}
          style={{
            marginTop: 0,
          }}>
          Manage your <span>brand community</span>
        </h2>
        <div className={styles.acc_header}>
          <div className={styles.acc_image}>
            <img
              src={
                singleCommunityProps.selectedCommunity?.brand?.businessDetails
                  ?.logoUrl
              }
              alt="invitation"
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                objectFit: "cover",
              }}
            />
          </div>
          <div>
            <p className={styles.acc_name}>
              {
                singleCommunityProps.selectedCommunity?.brand?.businessDetails
                  ?.name
              }
            </p>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className={styles.menuWrapper} style={{ margin: 0 }}>
            <ul>
              <li
                onClick={() => setShow((prev) => !prev)}
                role="button"
                className={styles.menuItem}
                style={{
                  padding: 0,
                  ...(show && { height: "auto" }),
                }}>
                <div style={{ width: "100%" }}>
                  <div
                    className={styles.flex}
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      minHeight: 60,
                    }}>
                    <p className={styles.username}>Withdraw from community</p>
                    <img
                      src={"/icons/chevron-right.svg"}
                      style={{
                        ...(show && {
                          transform: "rotate(-90deg)",
                        }),
                      }}
                      alt="account"
                    />
                  </div>
                </div>
              </li>
              <AnimatePresence>
                {show && (
                  <motion.form
                    onSubmit={onSubmit}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      backgroundColor: "#1F2B30",
                      borderBottomRightRadius: 12,
                      borderBottomLeftRadius: 12,
                      padding: "14px 28px",
                    }}>
                    <h5 className={styles.formH5}>
                      Brands keep you in their community to consider you for
                      future campaign’s.
                    </h5>

                    <h6 className={styles.formH6}>
                      Can you please tell us why you want to withdraw from this
                      community?
                    </h6>

                    <p className={styles.formP}>
                      This helps us better the experience for our brand-creator
                      community
                    </p>

                    <TextArea
                      rows={6}
                      style={{
                        backgroundColor: "transparent",
                        marginBlock: 18,
                        fontFamily: "inherit",
                        borderColor: "#533AF5",
                        padding: "8px 14px",
                      }}
                      value={value}
                      onChange={onChange}
                      placeholder="Enter Text..."
                    />

                    <MainButton
                      text={"Submit"}
                      isLoading={removeUserMutation.isLoading}
                      onClick={() => {
                        removeUserMutation.mutate({
                          communityId:
                            singleCommunityProps?.selectedCommunity?._id,
                          reason: value,
                        });
                      }}
                      disabled={!value.trim()}
                    />

                    {/* <Button
                      htmlType="submit"
                      className={styles.btn}
                      style={{ width: "100%" }}>
                      Submit
                    </Button> */}
                  </motion.form>
                )}
              </AnimatePresence>
            </ul>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default Community;
