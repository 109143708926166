import React from "react";
import clsx from "clsx";

import { FaInstagram, FaYoutube } from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";

import styles from "./styles.module.css";

const OptionTile = ({
  isSelected = false,
  username,
  platform = "instagram",
  handleClick = () => {},
}) => {
  return (
    <div
      className={clsx(styles.container, { [styles.selected]: isSelected })}
      onClick={handleClick}>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {platform === "instagram" ? (
          <FaInstagram color={isSelected ? "#533AF5" : "#FFF"} size={15} />
        ) : (
          <FaYoutube color={isSelected ? "#533AF5" : "#FFF"} size={15} />
        )}{" "}
        {username}
      </div>
      {isSelected && <HiCheckCircle color={"#533AF5"} size={12} />}
    </div>
  );
};

export default OptionTile;
