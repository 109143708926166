import { sendRequest } from "../sendRequest";

export const getAppState = ({ connectRequestId }) => {
  const queryKey = ["app-state"];
  const queryFunction = () =>
    sendRequest({
      method: "get",
      url: `/influencer/me/app-state?appType=creatorConnect&${
        connectRequestId ? `connectRequestId=${connectRequestId}` : ""
      }`,
      isV2: true,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
