import React, { useState } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import Typography from "antd/es/typography/Typography";
import { MdModeEdit } from "react-icons/md";

const InputEdit = ({ mainText, onClick }) => {
  return (
    <div className={clsx(styles.main)}>
      <Typography className={clsx(styles.text)}>{mainText}</Typography>
      <div onClick={onClick} className={clsx(styles.circle)}>
        <MdModeEdit style={{ color: "var(--primaryColor)" }} size={20} />
      </div>
    </div>
  );
};

export default InputEdit;
