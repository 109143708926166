import React from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const PermissionErrorDrawer = ({ handleBack, visible }) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main>
        <p className={styles.heading} style={{ marginBottom: "30px" }}>
          You did not select all <br />
          the permissions while connecting <br />
          Instagram 😅
        </p>
        <p className={styles.subHeading} style={{ marginBottom: "20px" }}>
          We require all the permission to work properly. Select all the
          permissions and try again!
        </p>
        <img
          src="/img/fbPermission.png"
          style={{ height: "357px", width: "300px" }}
          alt="fb_error"
        />
      </main>
    </CustomDrawer>
  );
};

export default PermissionErrorDrawer;
