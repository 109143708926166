import React, { useEffect } from "react";
import { Row } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { AnimatePresence } from "framer-motion";

import RequestHeader from "components/RequestHeader";
import OptionContainer from "components/Shared/OptionContainer";
import OTPBrand from "components/Verify/OTP/Brand";
import SocialsBrand from "components/Verify/Socials/Brand";

import FooterButtonBrand from "../../components/Verify/FooterButtonBrand";
import DesktopScreenBrand from "components/Verify/DesktopScreen/Brand";

import { useGlobalLinkStateContext } from "context/GlobalLinkStateContext";

import useScreenDimensions from "Utility/Dimention";

import { getBrandDetails } from "../../handlers/public/getBrandDetails";

import animationData from "assets/lottie/loading.json";

import styles from "./styles.module.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const BrandLink = () => {
  const { buttonState, setBrandId } = useGlobalLinkStateContext();

  const { brandId } = useParams();

  useEffect(() => {
    if (brandId) {
      setBrandId(brandId);
    }
  }, [brandId]);

  const { queryKey, queryFunction } = getBrandDetails({ brandId });

  const { isLoading: isLoadingBrandData, isError } = useQuery(
    queryKey,
    queryFunction,
    { enabled: Boolean(brandId) }
  );

  const { width } = useScreenDimensions();
  const showDesktop = width > 1000 ? true : false;

  const getStatus = (type) => {
    switch (type) {
      case "otp": {
        if (["start", "otp", "verify"].includes(buttonState)) {
          return "inProgress";
        }
        return "completed";
      }

      case "social":
        if (buttonState === "platformSelect") {
          return "inProgress";
        }
        return "notStarted";

      default:
        return "completed";
    }
  };

  // useEffect(() => {
  //   if (loading) {
  //     return;
  //   }

  //   if (buttonState === "start" && signedIn) {
  //     return auth?.signOut().then(() => window.location.reload());
  //   }
  // }, [signedIn, buttonState]);

  if (isLoadingBrandData) {
    return (
      <Row justify="center" style={{ width: "100%", marginTop: 100 }}>
        <Lottie options={defaultOptions} height={160} width={160} />
      </Row>
    );
  }

  if (isError) {
    return <p>Something Went Wrong</p>;
  }

  if (showDesktop) {
    return <DesktopScreenBrand />;
  }

  return (
    <div className={styles.container}>
      <div
        id="recaptcha-container"
        style={{ minHeight: "15px", margin: "-20px" }}
      />
      <RequestHeader ShowFull={false} brandId={brandId} />
      <div>
        <AnimatePresence>
          <div key="test">
            <OptionContainer
              title={"1. Verify Yourself"}
              body={<OTPBrand />}
              status={getStatus("otp")}
            />

            <OptionContainer
              title={"2. Verify Social’s "}
              body={<SocialsBrand />}
              status={getStatus("social")}
            />
            <OptionContainer
              title={"3. Confirm and join community"}
              body={<div />}
              status={"notStarted"}
              containerStyles={{ borderRadius: "0px 0px 8px 8px" }}
            />
          </div>
        </AnimatePresence>
      </div>

      <div className={styles.footer}>
        <Row justify={"center"} style={{ marginBottom: "20px" }}>
          <p>
            <span className={styles.footerTitle}>Secured by</span>
            <span>
              <img
                src="/img/logo.png"
                alt="logo"
                style={{ width: "15px", height: "9.985px" }}
              />
            </span>
            <span className={styles.cloutflow}>Cloutflow</span>
          </p>
        </Row>
        <FooterButtonBrand />
      </div>
      {/* </div> */}
    </div>
  );
};

export default BrandLink;
