import React, { useState } from "react";
import { message } from "antd";
import { RecaptchaVerifier } from "firebase/auth";

import { auth } from "../../../Firebase";
import { useAuthContext } from "../../../context/AuthContext";

import MainButton from "../../Shared/Button/MainButton";

import { useGlobalConnectContextContext } from "../context/GlobalConnectContext";

const FooterButton = () => {
  const { buttonState, updateButtonState, userInput, setUserInput } =
    useGlobalConnectContextContext();

  const { sendOtpFirebase, verifyOtpFirebase } = useAuthContext();

  const [firebaseOtpLoading, setFirebaseOtpLoading] = useState(false);

  const handleSendOtp = async () => {
    try {
      // auth.settings.appVerificationDisabledForTesting = true;

      if (
        userInput.phoneNumber.length < 7 ||
        userInput.phoneNumber.length > 12
      ) {
        return message.error("Invalid Number");
      }

      const phone = userInput?.countryCode + userInput?.phoneNumber;
      setFirebaseOtpLoading(true);
      let appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      sendOtpFirebase(phone, appVerifier)
        .then((result) => {
          if (result?.verificationId) {
            updateButtonState("verify");
            setUserInput((userInput) => ({
              ...userInput,
              uid: result?.verificationId,
            }));
          } else {
            message.error(
              "We detected a suspicious login, Please refresh the app"
            );
          }
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        })
        .catch(async (err) => {
          message.error(err?.code);
          setFirebaseOtpLoading(false);
          appVerifier.clear();
        });
    } catch (err) {
      // backend Api to throw error
      console.log("err", err);
      message.error(
        err?.code + "We detected a suspicious login, Please refresh the app"
      );

      setFirebaseOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setFirebaseOtpLoading(true);
    await verifyOtpFirebase(userInput?.otp, userInput?.uid).catch(
      async (err) => {
        setFirebaseOtpLoading(false);
        return message.error(err?.code + " ,Please Retry");
      }
    );
  };

  const getButtonProps = () => {
    // start->otp->verify->platformSelect
    switch (buttonState) {
      case "login":
        return {
          text: "Send OTP",
          disabled: !Boolean(userInput.phoneNumber),
          onClick: () => {
            if (userInput.phoneNumber) {
              handleSendOtp();
            }
          },
          loading: firebaseOtpLoading,
        };
      case "verify":
        return {
          text: "Verify OTP",
          onClick: () => {
            handleVerifyOtp();
          },
          loading: firebaseOtpLoading,
          disabled: !Boolean(userInput.otp),
        };

      default:
        return { text: "", onClick: () => {} };
    }
  };

  return (
    <MainButton
      type="primary"
      text={getButtonProps()?.text}
      onClick={() => getButtonProps()?.onClick()}
      isLoading={getButtonProps()?.loading || false}
      disabled={getButtonProps().disabled || false}
    />
  );
};

export default FooterButton;
