import React from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const NoFbPage = ({ handleBack, visible }) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main>
        <p className={styles.heading} style={{ marginBottom: "30px" }}>
          You don’t have any Facebook page 😅
        </p>
        <p className={styles.subHeading} style={{ marginBottom: "20px" }}>
          To connect your Instagram profile Successfully ,You need to create a
          Facebook page and link it with your Instagram profile
        </p>
      </main>
    </CustomDrawer>
  );
};

export default NoFbPage;
