import React from "react";
import { Switch, message } from "antd";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useStateContext } from "context/StateContext";
import { useAuthContext } from "context/AuthContext";

import { getAppState } from "handlers/me";

import { putMe } from "handlers/me";

import styles from "./styles.module.css";

const ManagePermissions = () => {
  const queryClient = useQueryClient();
  const { signedIn } = useAuthContext();
  const { connectRequestId } = useStateContext();

  const { queryKey: stateQueryKey, queryFunction: stateQueryFunction } =
    getAppState({ connectRequestId });
  const { data: appStateData } = useQuery(stateQueryKey, stateQueryFunction, {
    enabled: Boolean(connectRequestId) && Boolean(signedIn),
  });

  const putMeMutation = useMutation(putMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(stateQueryKey);
      message.success("Preferences Updated");
    },
    onError: () => {
      message.error("Something Went wrong while Updating");
    },
  });

  return (
    <div className={styles.container}>
      <p className={styles.title}>Automate your connect account?</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <p className={styles.listItem}>
          Auto Accept all future invites from verified brands?
        </p>
        <Switch
          checked={appStateData?.data?.community?.isAutoInvite}
          onChange={() =>
            putMeMutation.mutate({
              communityIsAutoInvite:
                !appStateData?.data?.community?.isAutoInvite,
              communityIsDiscoverable:
                appStateData?.data?.community?.isDiscoverable,
            })
          }
          loading={putMeMutation.isLoading}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <p className={styles.listItem}>
          Let other brands on Cloutflow reach me.
        </p>
        <Switch
          checked={appStateData?.data?.community?.isDiscoverable}
          onChange={() =>
            putMeMutation.mutate({
              communityIsAutoInvite:
                appStateData?.data?.community?.isAutoInvite,
              communityIsDiscoverable:
                !appStateData?.data?.community?.isDiscoverable,
            })
          }
          loading={putMeMutation.isLoading}
        />
      </div>
    </div>
  );
};

export default ManagePermissions;
