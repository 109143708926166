import React, { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import MainButton from "components/Shared/Button/MainButton";
import { TypeAnimation } from "react-type-animation";
import { Typography } from "antd";

const LoginMain = ({ setLoginState }) => {
  return (
    <div className={clsx(styles.innerBody)}>
      <TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          "Say goodbye to screenshots",
          1000, // wait 1s before replacing "Mice" with "Hamsters"
          "Say hello to brand Collabs",
          1000,
        ]}
        wrapper="span"
        speed={50}
        style={{
          fontSize: "22px",
          fontWeight: 600,
          display: "inline-block",
          color: "white",
        }}
        repeat={Infinity}
      />

      <MainButton text="Continue" onClick={() => setLoginState(1)} />
      <Typography>
        Cloutflow connect is a product of @Cloutflow.{" "}
        <a href="https://cloutflow.com/" target="_blank">
          Know more about Cloutflow-Connect
        </a>
      </Typography>
    </div>
  );
};

export default LoginMain;
