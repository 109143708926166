import { sendRequest } from "../sendRequest";

export const verifyOTP = ({ uid, otp }) => {
  return sendRequest({
    method: "post",
    url: `/influencer/login-token`,
    body: {
      uid,
      otp,
    },
  }).then((res) => res.data);
};
