import React from "react";
import { Select } from "antd";

import { countryCodeArray } from "../../../assets/country_code";

import InputMain from "../../Shared/Input";

import styles from "../styles.module.css";

import { useGlobalConnectContextContext } from "../context/GlobalConnectContext";

const { Option } = Select;

const PhoneInput = () => {
  const { buttonState, userInput, setUserInput } =
    useGlobalConnectContextContext();

  if (buttonState === "verify") return <div />;

  return (
    <div>
      <p className={styles.phoneLabel}>
        OTP will be sent to your number for verification
      </p>
      <div style={{ display: "flex", gap: "0", marginTop: 16 }}>
        <Select
          size={"large"}
          className="cccSelect"
          value={userInput.countryCode}
          onChange={(value) =>
            setUserInput((userInput) => ({
              ...userInput,
              countryCode: value,
            }))
          }
        >
          {countryCodeArray.map((item) => (
            <Option value={item.dial_code} key={item.name}>
              {item.flag} {item.dial_code}
            </Option>
          ))}
        </Select>

        <InputMain
          mainText={"Phone number"}
          phoneNumber={true}
          type={"tel"}
          value={userInput.phoneNumber}
          handleChange={(val) =>
            setUserInput((userInput) => ({
              ...userInput,
              phoneNumber: val,
            }))
          }
        />
      </div>
    </div>
  );
};

export default PhoneInput;
