import { sendRequest } from "../sendRequest";

// export type getInvitedInfluencersResult = {
//   status: string;
//   message: string;
//   data: {
//     invited: InvitedInfluencerCommunity[];
//     pageNo: number;
//   };
// };
// type CommunityProps = {
//   limit: number;
//   search: string;
//   inviteBy?: string;
// };

export const getUserCommunities = ({ limit }) => {
  const queryKey = ["community", limit];
  const queryFunction = ({ pageParam }) =>
    sendRequest({
      method: "get",
      url: `/influencer/community?limit=${limit}&pageNo=${pageParam || 1}`,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
