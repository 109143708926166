import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Switch, message } from "antd";

import { getMe, putMe } from "handlers/me";

import { useAuthContext } from "context/AuthContext";

import styles from "./../styles.module.css";

const Preferences = () => {
  const { signedIn } = useAuthContext();
  const { queryKey, queryFunction } = getMe();

  const { data: userData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(signedIn),
  });
  const queryClient = useQueryClient();

  const putMeMutation = useMutation(putMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      message.success("Preferences Updated");
    },
    onError: () => {
      message.error("Something Went wrong while Updating");
    },
  });

  return (
    <div>
      <h6 className={styles.emptyHeader}>Set your preferences</h6>

      <div className={styles.pref_container}>
        <p className={styles.pref_title}>Automate your connect account?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <p className={styles.pref_listItem}>
            Auto Accept all future invites from verified brands?
          </p>
          <Switch
            checked={userData?.data?.community?.isAutoInvite}
            onChange={() =>
              putMeMutation.mutate({
                communityIsAutoInvite: !userData?.data?.community?.isAutoInvite,
                communityIsDiscoverable:
                  userData?.data?.community?.isDiscoverable,
              })
            }
            loading={putMeMutation.isLoading}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <p className={styles.pref_listItem}>
            Let other brands on Cloutflow reach me.
          </p>
          <Switch
            checked={userData?.data?.community?.isDiscoverable}
            onChange={() =>
              putMeMutation.mutate({
                communityIsAutoInvite: userData?.data?.community?.isAutoInvite,
                communityIsDiscoverable:
                  !userData?.data?.community?.isDiscoverable,
              })
            }
            loading={putMeMutation.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Preferences;
