import React, { createContext, useState } from "react";

const GlobalConnectContext = createContext(null);

export default function GlobalConnectContextProvider({ children }) {
  // login->verify
  const [buttonState, setButtonState] = useState("login");

  const updateButtonState = (newValue) => {
    // login->verify
    setButtonState(newValue);
  };

  const [userInput, setUserInput] = useState({
    countryCode: "+91",
    phoneNumber: "",
    otp: "",
    uid: "",
  });

  const contextValue = {
    buttonState,
    updateButtonState,
    userInput,
    setUserInput,

    // firstChange,
  };

  return (
    <GlobalConnectContext.Provider value={contextValue}>
      {children}
    </GlobalConnectContext.Provider>
  );
}

export const useGlobalConnectContextContext = () => {
  const context = React.useContext(GlobalConnectContext);
  if (!context) {
    throw new Error(
      " useGlobalConnectContextContext must be used within a GlobalConnectContext Provider"
    );
  }
  return context;
};
