import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";

import AuthContextProvider from "context/AuthContext";
import StateContextProvider from "context/StateContext";
import GlobalLinkStateContextProvider from "context/GlobalLinkStateContext";
import GlobalStyles from "./Styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ErrorBoundary>
        <AuthContextProvider>
          <StateContextProvider>
            <GlobalLinkStateContextProvider>
              <GlobalStyles />
              <ConfigProvider
                theme={{
                  token: { colorPrimary: "#533AF5", colorTextBase: "#FFF" },
                  components: {
                    Drawer: {
                      colorBgElevated: "#1F2B32",
                      colorText: "#FFF",
                    },
                    Divider: {
                      colorSplit: "rgba(255, 255, 255, 0.10)",
                    },
                  },
                }}>
                <App />
              </ConfigProvider>
            </GlobalLinkStateContextProvider>
          </StateContextProvider>
        </AuthContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
