import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";

import Login from "screens/Login/Login";
import ConnectInstagram from "screens/ConnectInstagram";
import Home from "screens/Home";
import Test from "screens/Test";
import BrandLink from "screens/BrandLink";
import GlobalConnect from "screens/GlobalConnect";
import AcceptInvite from "screens/AcceptInvite";
import Accounts from "screens/Public/Accounts";

import TestComp from "./../components/Test";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/connect/instagram" element={<ConnectInstagram />} />
      <Route path="/home" element={<Home />} />
      <Route path="/test" element={<TestComp />} />

      <Route path="/brand/:brandId" element={<BrandLink />} />
      <Route path="/" element={<Test />} />
      <Route path="/v1" element={<GlobalConnect />} />
      <Route path="/v1/acceptonboarded" element={<AcceptInvite />} />

      <Route path="/public/accounts" element={<Accounts />} />
    </ReactRoutes>
  );
};

export default Routes;
