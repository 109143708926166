import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .highlight {
    color: #f00;
  }
  :root {
    --primaryColor: #533AF5;
    --secondaryColor: rgba(255, 255, 255, 0.50);
    --textColor:#FFF;
    --primaryTextColor:white;
    --primaryTextSize:22px;
    --secondaryTextColor:#533AF5;

  }

`;

export default GlobalStyles;
