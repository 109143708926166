import React, { useEffect } from "react";
import { Row } from "antd";
import { useQuery } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { AnimatePresence } from "framer-motion";

import RequestHeader from "components/RequestHeader";
import OptionContainer from "components/Shared/OptionContainer";
import OTP from "components/Verify/OTP";
import Socials from "components/Verify/Socials";
import Community from "components/Verify/Community";
import ManagePermissions from "components/Verify/ManagePermissions";
import FooterButton from "components/Verify/FooterButton";
import DesktopScreen from "components/Verify/DesktopScreen";

import { useStateContext } from "context/StateContext";
import { useAuthContext } from "context/AuthContext";
import { auth } from "../../Firebase";
import useScreenDimensions from "Utility/Dimention";

import { getPublicDetails, getAppState } from "handlers/me";

import animationData from "assets/lottie/loading.json";

import styles from "./styles.module.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Test = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { signedIn, loading, firstRender } = useAuthContext();
  const { code, fbState, connectRequestId, state, setState, ytState, err } =
    useStateContext();

  const navigate = useNavigate();

  const { width } = useScreenDimensions();
  const showDesktop = width > 1000 ? true : false;

  const getStatus = (type) => {
    const openedStatus = state[type]?.opened || false;
    const completedStatus = state[type]?.completed || false;

    if (completedStatus) {
      return "completed";
    }

    if (openedStatus && !completedStatus) {
      return "inProgress";
    }
    if (!openedStatus) {
      return "notStarted";
    }
    return "notStarted";
  };

  const { queryKey, queryFunction } = getPublicDetails({ connectRequestId });
  const { queryKey: stateQueryKey, queryFunction: stateQueryFunction } =
    getAppState({ connectRequestId });

  const {
    data: publicData,
    isLoading: publicDataLoading,
    isError: publicDataError,
  } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(connectRequestId),
  });

  const {
    data: appStateData,
    isLoading: appStateLoading,
    isError: appDataError,
  } = useQuery(stateQueryKey, stateQueryFunction, {
    enabled: Boolean(connectRequestId) && Boolean(signedIn),
  });

  const phoneNumber = String(publicData?.data?.phoneNumber || "");

  const platformInvited = publicData?.data?.platform;

  useEffect(() => {
    if (loading || appStateLoading) {
      return;
    }

    if (!Boolean(platformInvited)) {
      return;
    }

    if (
      appStateData?.data?.isConnectRequestAddedToCommunity &&
      firstRender.current
    ) {
      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: false,
          completed: true,
        },
        community: {
          opened: false,
          completed: true,
        },
      });
      firstRender.current = false;
      return;
    }
    if (
      firstRender.current &&
      (platformInvited === "instagram"
        ? appStateData?.data?.socialMediaAccounts?.instagram?.length > 0 ||
          (code && fbState) ||
          signedIn
        : appStateData?.data?.socialMediaAccounts?.youtube?.length > 0 ||
          (code && ytState) ||
          signedIn)
    ) {
      console.log("2");
      setState({
        otp: {
          opened: false,
          completed: true,
        },
        social: {
          opened: true,
          completed: false,
        },
        community: {
          opened: false,
          completed: false,
        },
      });
      firstRender.current = false;
      return;
    }

    if (!signedIn && firstRender.current) {
      setState({
        otp: {
          opened: true,
          completed: false,
        },
        social: {
          opened: false,
          completed: false,
        },
        community: {
          opened: false,
          completed: false,
        },
      });
      firstRender.current = false;
      return;
    }
  }, [
    code,
    fbState,
    signedIn,
    appStateData?.data,
    loading,
    firstRender.current,
    state,
    appStateLoading,
    publicData,
    platformInvited,
    connectRequestId,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.keys().next().done) {
      navigate("/v1");
    }
  }, [window.location.search]);

  useEffect(() => {
    if (connectRequestId && !Boolean(searchParams.get("connectRequestId"))) {
      if (err) {
        return setSearchParams(() => ({ connectRequestId }));
      }
      return setSearchParams(() => ({
        code: Boolean(code) ? code : undefined,
        state:
          Boolean(fbState) || Boolean(ytState)
            ? `${fbState || ytState}*${
                fbState ? "instagram" : "youtube"
              }*${connectRequestId}`
            : undefined,
        connectRequestId,
      }));
    }
  }, [connectRequestId, searchParams]);

  useEffect(() => {
    if (
      phoneNumber &&
      auth?.currentUser?.phoneNumber &&
      phoneNumber !== auth?.currentUser?.phoneNumber
    ) {
      auth?.signOut().then(() => window.location.reload());
    }
  }, [auth?.currentUser?.phoneNumber, phoneNumber]);

  if (publicDataLoading) {
    return (
      <Row justify="center" style={{ width: "100%", marginTop: 100 }}>
        <Lottie options={defaultOptions} height={160} width={160} />
      </Row>
    );
  }

  if (publicDataError || appDataError) {
    return <div />;
  }

  if (showDesktop) {
    return <DesktopScreen setState={setState} state={state} />;
  }

  return (
    <div className={styles.container}>
      <div
        id="recaptcha-container"
        style={{ minHeight: "15px", margin: "-20px" }}
      />
      <RequestHeader
        ShowFull={state?.otp?.opened}
        connectRequestId={connectRequestId}
      />
      <div>
        <AnimatePresence>
          <div key="test">
            {appStateData?.data?.isConnectRequestAddedToCommunity && (
              <OptionContainer
                title={"Invitation Accepted"}
                body={
                  <p className={styles.completedTitle}>
                    Manage your community on <a href="/">Cloutflow </a>
                  </p>
                }
                status={"completed"}
                containerStyles={{ borderRadius: "8px 8px 0px 0px" }}
                hardOpen={true}
              />
            )}
            <OptionContainer
              title={"1. Verify Yourself"}
              body={<OTP />}
              status={getStatus("otp")}
              containerStyles={
                !appStateData?.data?.isConnectRequestAddedToCommunity
                  ? { borderRadius: "8px 8px 0px 0px" }
                  : undefined
              }
            />

            <OptionContainer
              title={"2. Verify Social’s "}
              body={<Socials />}
              status={getStatus("social")}
            />
            <OptionContainer
              title={"3. Confirm and join community"}
              body={<Community />}
              status={getStatus("community")}
              containerStyles={{ borderRadius: "0px 0px 8px 8px" }}
            />
          </div>
        </AnimatePresence>
      </div>

      {/* <div style={{ position: "relative", width: "100%", minHeight: "50px" }}> */}
      {appStateData?.data?.isConnectRequestAddedToCommunity && (
        <ManagePermissions />
      )}
      <div className={styles.footer}>
        <Row justify={"center"} style={{ marginBottom: "20px" }}>
          <p>
            <span className={styles.footerTitle}>Secured by</span>
            <span>
              <img
                src="/img/logo.png"
                alt="logo"
                style={{ width: "15px", height: "9.985px" }}
              />
            </span>
            <span className={styles.cloutflow}>Cloutflow</span>
          </p>
        </Row>
        <FooterButton connectRequestId={connectRequestId} />
      </div>
      {/* </div> */}
    </div>
  );
};

export default Test;
