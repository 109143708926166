import React from "react";

import { OtpInput } from "reactjs-otp-input";

import styles from "../styles.module.css";

import { useGlobalConnectContextContext } from "../context/GlobalConnectContext";

const OTPInputComp = () => {
  const { buttonState, userInput, setUserInput } =
    useGlobalConnectContextContext();

  if (buttonState === "login") return <div />;

  return (
    <div>
      {" "}
      <>
        <OtpInput
          value={userInput.otp}
          onChange={(value) => {
            setUserInput((userInput) => ({
              ...userInput,
              otp: String(value),
            }));
          }}
          numInputs={6}
          isInputNum
          containerStyle={{ display: "flex", gap: "5px" }}
          inputStyle={{
            width: "20px",
            height: "25px",
            fontSize: "1rem",
            borderRadius: 8,
            border: "1px solid #FFF",
            color: "#FFF",
            backgroundColor: "transparent",
          }}
        />
        <p className={styles.otpLabel}>
          OTP sent to{" "}
          {userInput?.phoneNumber && (
            <span>
              {userInput?.phoneNumber?.slice(0, 4)}
              {Array((userInput?.phoneNumber).length - 6)
                .fill("*")
                .join("")}
              {userInput?.phoneNumber?.slice(-2)}.
            </span>
          )}
          <br />
          Enter OTP to verify your phone number
        </p>
      </>
    </div>
  );
};

export default OTPInputComp;
