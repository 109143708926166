import React, { createContext, useState, useRef } from "react";
import { useParams } from "react-router-dom";

const GlobalLinkStateContext = createContext(null);

export default function GlobalLinkStateContextProvider({ children }) {
  // start->otp->verify->platformSelect
  const [buttonState, setButtonState] = useState("start");
  const [brandId, setBrandId] = useState("");

  const updateButtonState = (newValue) => {
    // start->otp->verify->platformSelect
    setButtonState(newValue);
  };

  const [globalDetails, setGlobalDetails] = useState({
    countryCode: "+91",
    phoneNumber: "",
    platform: "instagram",
    otp: "",
  });

  const [uid, setUid] = useState("");

  const contextValue = {
    buttonState,
    updateButtonState,
    brandId,
    globalDetails,
    setGlobalDetails,
    uid,
    setUid,
    setBrandId,
    // firstChange,
  };

  return (
    <GlobalLinkStateContext.Provider value={contextValue}>
      {children}
    </GlobalLinkStateContext.Provider>
  );
}

export const useGlobalLinkStateContext = () => {
  const context = React.useContext(GlobalLinkStateContext);
  if (!context) {
    throw new Error(
      "useGlobalLinkStateContext must be used within a GlobalLinkStateContextProvider"
    );
  }
  return context;
};
