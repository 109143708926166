import { sendRequest } from "../sendRequest";

export const getBrandDetails = ({ brandId }) => {
  const queryKey = ["brand", "getBrandDetails", brandId];
  const queryFunction = () =>
    sendRequest({
      method: "GET",
      url: `/public/brand?brandId=${brandId}`,
    }).then((res) => res.data);

  return { queryKey, queryFunction };
};
