import React from "react";

import { AnimatePresence } from "framer-motion";
import FooterButtonBrand from "../FooterButtonBrand";

import { useGlobalLinkStateContext } from "../../../context/GlobalLinkStateContext";

import PubLicDetails from "../PublicDetails";
import OptionContainer from "components/Shared/OptionContainer";
import OTPBrand from "components/Verify/OTP/Brand";
import SocialsBrand from "components/Verify/Socials/Brand";

const DesktopScreenBrand = () => {
  const { buttonState } = useGlobalLinkStateContext();

  const getStatus = (type) => {
    switch (type) {
      case "otp": {
        if (["start", "otp", "verify"].includes(buttonState)) {
          return "inProgress";
        }
        return "completed";
      }
      case "social":
        if (buttonState === "platformSelect") {
          return "inProgress";
        }
        return "notStarted";

      default:
        return "completed";
    }
  };

  return (
    <>
      <div
        id="recaptcha-container"
        style={{ minHeight: "15px", marginTop: "-15px" }}
      />

      <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
        <div style={{ background: "#1f2b32", margin: "0 auto", width: "100%" }}>
          <PubLicDetails />
        </div>
        <section style={{ background: "#171F2A", padding: "200px 40px" }}>
          <AnimatePresence>
            <div key="main">
              <OptionContainer
                title={"1. Verify Yourself"}
                body={
                  <>
                    <OTPBrand />
                    <div style={{ marginTop: 10 }}>
                      <FooterButtonBrand />
                    </div>
                  </>
                }
                status={getStatus("otp")}
              />

              <OptionContainer
                title={"2. Verify Social’s "}
                body={
                  <>
                    <SocialsBrand />
                    <div style={{ marginTop: 10 }}>
                      <FooterButtonBrand />
                    </div>
                  </>
                }
                status={getStatus("social")}
              />
              <OptionContainer
                title={"3. Confirm and join community"}
                body={
                  <>
                    <div />
                    <div style={{ marginTop: 10 }}>
                      <FooterButtonBrand />
                    </div>
                  </>
                }
                status={"notStarted"}
                containerStyles={{ borderRadius: "0px 0px 8px 8px" }}
              />
            </div>
          </AnimatePresence>
        </section>
      </div>
    </>
  );
};

export default DesktopScreenBrand;
