import React from "react";
import CustomDrawer from "components/Shared/CustomDrawer";
import { Space } from "antd";

import styles from "./styles.module.css";

import { capitalizeFirstLetter } from "Utility/capitalizeFirstLetter";

const PlatformSuccess = ({
  platform,
  username,
  profilePic,
  visible,
  handleClose,
}) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleClose} hasTitle={false}>
      <div style={{ marginTop: "120px" }}>
        <p className={styles.heading}>
          Hurray your {capitalizeFirstLetter(platform)}
          <br /> profile is connected 🥳
        </p>
        <section className={styles.card}>
          <Space size={35}>
            <img src={profilePic} alt="profile" className={styles.image} />
            <p className={styles.username}>@{username}</p>
          </Space>
        </section>
      </div>
    </CustomDrawer>
  );
};

export default PlatformSuccess;
