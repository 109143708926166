import React from "react";
import clsx from "clsx";
import styles from "./styles.module.css";

import { Typography } from "antd";

const { Text, Paragraph } = Typography;

const TitleMain = ({
  text,
  isParagraph,
  start,
  end,
  customStyle,
  className = "",
}) => {
  const firstPart = text.slice(0, start);
  const highlightedPart = text.slice(start, end + 1);
  const lastPart = text.slice(end + 1);

  return isParagraph ? (
    <Paragraph style={{ margin: 0, height: "max-content" }}>
      <Text className={clsx(styles.primary)} style={customStyle}>
        {firstPart}
      </Text>
      <Text className={clsx(styles.secondary)} style={customStyle}>
        {highlightedPart}
      </Text>
      <Text className={clsx(styles.primary)} style={customStyle}>
        {lastPart}
      </Text>
    </Paragraph>
  ) : (
    <Paragraph style={{ margin: 0, height: "max-content" }}>
      <Text className={clsx(styles.primary)} style={customStyle}>
        {text}
      </Text>
    </Paragraph>
  );
};

export default TitleMain;
