import React from "react";
import { useQuery } from "react-query";
import { Row } from "antd";

import { AnimatePresence } from "framer-motion";
import FooterButton from "components/Verify/FooterButton";

import { useStateContext } from "../../../context/StateContext";
import { useAuthContext } from "../../../context/AuthContext";

import { getAppState } from "handlers/me";

import PubLicDetails from "../PublicDetails";
import OptionContainer from "components/Shared/OptionContainer";
import OTP from "components/Verify/OTP";
import Socials from "components/Verify/Socials";
import Community from "components/Verify/Community";
import ManagePermissions from "components/Verify/ManagePermissions";

import styles from "./styles.module.css";

const DesktopScreen = ({ state, setState }) => {
  const { connectRequestId, brandId } = useStateContext();

  const { signedIn } = useAuthContext();

  const { queryKey: stateQueryKey, queryFunction: stateQueryFunction } =
    getAppState({ connectRequestId });
  const { data: appStateData } = useQuery(stateQueryKey, stateQueryFunction, {
    enabled: Boolean(connectRequestId) && Boolean(signedIn),
  });

  const getStatus = (type) => {
    const openedStatus = state[type]?.opened || false;
    const completedStatus = state[type]?.completed || false;

    if (completedStatus) {
      return "completed";
    }

    if (openedStatus && !completedStatus) {
      return "inProgress";
    }
    if (!openedStatus) {
      return "notStarted";
    }
    return "notStarted";
  };

  return (
    <>
      <div
        id="recaptcha-container"
        style={{ minHeight: "15px", marginTop: "-15px" }}
      />

      <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
        <div style={{ background: "#1f2b32", margin: "0 auto", width: "100%" }}>
          <PubLicDetails />
        </div>
        <section style={{ background: "#171F2A", padding: "200px 40px" }}>
          <AnimatePresence>
            <div key="main">
              {appStateData?.data?.isConnectRequestAddedToCommunity && (
                <OptionContainer
                  title={"Invitation Accepted"}
                  body={
                    <p className={styles.completedTitle}>
                      Manage your community on <a href="/">Cloutflow </a>
                    </p>
                  }
                  status={"completed"}
                  containerStyles={{ borderRadius: "8px 8px 0px 0px" }}
                  hardOpen={true}
                />
              )}
              <OptionContainer
                title={"1. Verify Yourself"}
                body={
                  <>
                    <OTP />
                    <div style={{ marginTop: 10 }}>
                      <FooterButton
                        state={state}
                        setState={setState}
                        connectRequestId={connectRequestId}
                      />
                    </div>
                  </>
                }
                status={getStatus("otp")}
                containerStyles={
                  !appStateData?.data?.isConnectRequestAddedToCommunity
                    ? { borderRadius: "8px 8px 0px 0px" }
                    : undefined
                }
              />

              <OptionContainer
                title={"2. Verify Social’s "}
                body={
                  <>
                    <Socials state={state} />
                    <div style={{ marginTop: 10 }}>
                      <FooterButton
                        state={state}
                        setState={setState}
                        connectRequestId={connectRequestId}
                      />
                    </div>
                  </>
                }
                status={getStatus("social")}
              />
              <OptionContainer
                title={"3. Confirm and join community"}
                body={
                  <>
                    <Community setState={setState} />
                    <div style={{ marginTop: 10 }}>
                      <FooterButton
                        state={state}
                        setState={setState}
                        connectRequestId={connectRequestId}
                      />
                    </div>
                  </>
                }
                status={getStatus("community")}
                containerStyles={{ borderRadius: "0px 0px 8px 8px" }}
              />
            </div>
          </AnimatePresence>

          {appStateData?.data?.isConnectRequestAddedToCommunity && (
            <div style={{ marginBlock: 20 }}>
              <ManagePermissions />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default DesktopScreen;
