import React, { useState, useRef, useEffect } from "react";
import { Space, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useQuery, useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import TitleMain from "components/Shared/Typography/TitleMain";
import PageHeader from "components/Shared/PageHeader";
import Option from "components/Shared/Option";
import SelectIgAccountDrawer from "components/PlatformConnect/SelectIgAccountDrawer";
import PlatformSuccess from "components/PlatformSuccess";
import FacebookErrorDrawer from "components/PlatformConnect/FacebookErrorDrawer";

import { getState } from "handlers/me";
import { connectIg } from "handlers/connectSocial";

import { setOfAllowedScopes } from "Utility/getFacebookScopes";

import styles from "./styles.module.css";

const ConnectInstagram = () => {
  const [hasBusinessAccount, setHasBusinessAccount] = useState(undefined);
  const [instaLinked, setInstaLinked] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [platformSuccessProps, setPlatformSuccessProps] = useState({
    visible: false,
    platform: "instagram",
    profilePic: "",
    username: "",
  });

  const [fbError, setFbError] = useState({
    visible: false,
    errorData: undefined,
    errorType: undefined,
  });

  const queryClient = useQueryClient();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") || "";
  const state = urlParams.get("state") || "";
  const isLinkError = urlParams.get("error") || "";

  const navigate = useNavigate();

  const { queryKey, queryFunction } = getState();
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(hasBusinessAccount) && Boolean(instaLinked),
  });

  //Testing Url
  const url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${process.env.REACT_APP_FB_KEY}&redirect_uri=http://localhost:3000/connect/instagram&scope=instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement&state=${data?.data}`;

  const [selectIgModalProps, setSelectIgModalProps] = useState({
    visible: false,
    acessToken: undefined,
    userIgs: undefined,
  });

  const connectIgMuatation = useMutation(connectIg);

  useEffect(() => {
    if (code && state) {
      return connectIgMuatation.mutate(
        {
          data: {
            code: code,
            // redirectUri: `${process.env.REACT_APP_URL}/connect/instagram`,
            redirectUri: `${process.env.REACT_APP_URL}/connect/instagram`,
          },
          token: state,
        },
        {
          onSuccess: (res) => {
            if (res.status === 200) {
              if (
                setOfAllowedScopes.some((scopeItem) => {
                  return !res?.data?.data?.scopes?.includes(scopeItem);
                })
              ) {
                return setFbError({
                  visible: true,
                  errorData: res?.data?.data,
                  errorType: "permission",
                });
              }

              if (res?.data?.data?.connectAccounts?.length === 0) {
                return setFbError({
                  visible: true,
                  errorData: res?.data?.data,
                  errorType: "noCreatedFb",
                });
              }

              if (
                res?.data?.data?.pages?.every(
                  (pageItem) => !pageItem.isPageLinkedToInsta
                )
              ) {
                return setFbError({
                  visible: true,
                  errorData: res?.data?.data,
                  errorType: "noInsta",
                });
              }

              if (
                res?.data?.data?.pages?.some(
                  (pageItem) =>
                    pageItem.isPageLinkedToInsta === true &&
                    pageItem.isInstaPageSelectedWithPage === false
                )
              ) {
                return setFbError({
                  visible: true,
                  errorData: res?.data?.data,
                  errorType: "nofbPage",
                });
              }
              return setSelectIgModalProps({
                visible: true,
                acessToken: res?.data?.data?.accessToken,
                userIgs: res?.data?.data?.pages,
              });
            }
          },
        }
      );
    } else {
      setSelectIgModalProps({
        visible: false,
        acessToken: undefined,
        userIgs: undefined,
      });
      setFbError({
        visible: false,
        errorData: undefined,
        errorType: undefined,
      });
    }
  }, [code, state]);

  return (
    <div className={styles.main}>
      <PageHeader handleClose={() => navigate("/")} />
      <div className={styles.mainBody}>
        <div className={styles.homeBody}>
          <div className={styles.textBody}>
            <TitleMain
              text="Securely connect Instagram profile."
              isParagraph={true}
              start={8}
              end={25}
              customStyle={{ fontSize: "26px", fontWeight: "700" }}
            />
            <div
              style={{
                display: "flex",
                gap: "2px",
              }}>
              <Typography
                style={{
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}>
                Powered by
              </Typography>
              <img src="/img/meta.png" style={{ height: "20px" }} />
            </div>
          </div>

          <div className={styles.g10}>
            <p className={styles.optionHeading}>
              Do you have have a Business Creator Instagram Account?
            </p>
            <div className={styles.options}>
              <Option
                isSelected={Boolean(hasBusinessAccount)}
                title={"Yes, Continue"}
                handleClick={() => setHasBusinessAccount(true)}
              />
              <Option
                title={"No, Help Me"}
                handleClick={() =>
                  window.open("https://cloutflow.com/", "_blank")
                }
              />
            </div>
          </div>
          <div className={styles.g10}>
            <p
              className={clsx(styles.optionHeading, {
                [styles.disabled]: !Boolean(hasBusinessAccount),
              })}>
              Is your Instagram profile connected to your Facebook page?
            </p>
            <div className={styles.options}>
              <Option
                isSelected={Boolean(instaLinked)}
                title={"Yes, Continue"}
                handleClick={() => setInstaLinked(true)}
                disabled={!Boolean(hasBusinessAccount)}
              />
              <Option
                title={"No, Help Me"}
                handleClick={() =>
                  window.open("https://cloutflow.com/", "_blank")
                }
                disabled={!Boolean(hasBusinessAccount)}
              />
            </div>
          </div>
          {Boolean(instaLinked) && (
            <div
              className={clsx(styles.fbButton, {
                [styles.loading]: isLoading,
              })}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                return window.open(url, "_self");
              }}>
              <img
                src="/img/fLogo.svg"
                style={{ padding: "8px" }}
                alt="fb"
                height={24}
                width={24}
              />
              Continue with Facebook
              {isLoading && (
                <Spin
                  size="small"
                  style={{ color: "white", marginLeft: "12px" }}
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                      color="#FFFFFF"
                    />
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
      <SelectIgAccountDrawer
        userIgs={selectIgModalProps.userIgs}
        acessToken={selectIgModalProps.acessToken}
        visible={selectIgModalProps.visible}
        state={state}
        handleBack={() => {
          setSelectIgModalProps({
            visible: false,
            acessToken: null,
            userIgs: null,
          });
          queryClient.invalidateQueries(queryKey);
          if (searchParams.has("code")) {
            searchParams.delete("code");
          }
          if (searchParams.has("state")) {
            searchParams.delete("state");
          }
          return setSearchParams(searchParams);
        }}
        handleSuccess={({ username, profilePic }) => {
          setPlatformSuccessProps({
            platform: "instagram",
            profilePic: profilePic,
            username: username,
            visible: true,
          });
          setTimeout(() => {
            navigate("/");
            setPlatformSuccessProps({
              visible: false,
              platform: "instagram",
              profilePic: undefined,
              username: undefined,
            });
          }, 5000);
        }}
      />
      <PlatformSuccess
        platform={platformSuccessProps.platform}
        handleClose={() => {}}
        profilePic={platformSuccessProps.profilePic}
        visible={platformSuccessProps.visible}
        username={platformSuccessProps.username}
      />
      <FacebookErrorDrawer
        visible={fbError.visible}
        errorType={fbError.errorType}
        errorData={fbError.errorData}
        handleBack={() => {
          setFbError({
            visible: false,
            errorData: undefined,
            errorType: undefined,
          });
          queryClient.invalidateQueries(queryKey);
          if (searchParams.has("code")) {
            searchParams.delete("code");
          }
          if (searchParams.has("state")) {
            searchParams.delete("state");
          }
          return setSearchParams(searchParams);
        }}
      />
    </div>
  );
};

export default ConnectInstagram;
