import React, { useState } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";

const InputMain = ({
  mainText,
  defaultFocused,
  type,
  phoneNumber,
  name,
  value,
  handleChange,
  errorMessage = "",
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(defaultFocused || false);
  return (
    <div style={{ width: "100%" }}>
      <div
        className={clsx(styles.main, {
          [styles.focused]: isFocused,
          [styles.red]: Boolean(errorMessage),
        })}>
        <p className={clsx(styles.helpText, { [styles.focused]: isFocused })}>
          {mainText}
        </p>

        <input
          placeholder={
            isFocused ? undefined : phoneNumber ? "Phone Number" : mainText
          }
          name={name}
          type={type}
          onFocus={() => {
            if (props.onFocus) props.onFocus();
            setIsFocused(true);
          }}
          className={clsx(styles.input)}
          onBlur={() => setIsFocused(false)}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          {...props}
        />
      </div>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default InputMain;
