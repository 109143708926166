import React from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const NoInstaFbPage = ({ handleBack, visible, accounts }) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main style={{ display: "grid", gap: 20 }}>
        <p className={styles.heading}>
          No Instagram account is <br />
          connected with the pages you
          <br /> have selected😅
        </p>
        <p className={styles.subHeading}>
          Facebook pages selected:
          {accounts
            ?.filter((accountItem) => !accountItem?.isPageLinkedToInsta)
            .map((pageItem) => {
              return <p className={styles.listItem}>{pageItem.pageName}</p>;
            })}
        </p>
        <p className={styles.subHeading}>
          Either select the Facebook page that is <br />
          connected to an Instagram account, or{" "}
          <a
            href="https://www.facebook.com/help/1148909221857370"
            className={styles.link}>
            Connect your Instagram with your Facebook page.
          </a>
        </p>

        <img
          src="/img/fbPage.png"
          style={{ height: "357px", width: "300px" }}
          alt="fb_error"
        />
      </main>
    </CustomDrawer>
  );
};

export default NoInstaFbPage;
