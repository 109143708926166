import React from "react";

import styles from "./styles.module.css";
import { Typography } from "antd";
import { CgProfile } from "react-icons/cg";
import { BiSolidChevronRight, BiNotification, BiLogOut } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineAutoFixNormal, MdOutlineRocketLaunch } from "react-icons/md";

const OptionTile = () => {
  const options = [
    {
      text: "Connected Accounts",
      icon: <CgProfile className={styles.icon} />,
    },
    {
      text: "Manage my community",
      icon: <AiOutlineHeart className={styles.icon} />,
    },
    {
      text: "Invitations",
      icon: <BiNotification className={styles.icon} />,
    },
    {
      text: "Account Automations",
      icon: <MdOutlineAutoFixNormal className={styles.icon} />,
    },
    {
      text: "Get Cloutflow",
      icon: <MdOutlineRocketLaunch className={styles.icon} />,
    },
    {
      text: "Logout",
      icon: <BiLogOut className={styles.icon} />,
    },
    {
      text: "What is connect?",
      icon: <AiOutlineInfoCircle className={styles.icon} />,
    },
  ];
  return (
    <div className={styles.container}>
      {options &&
        options.map((option) => (
          <>
            <Option option={option} />
            <div className={styles.li} />
          </>
        ))}
    </div>
  );
};

export default OptionTile;

const Option = ({ option }) => {
  return (
    <div className={styles.option}>
      <div className={styles.optionLeft}>
        <div className={styles.iconLeft}> {option.icon}</div>

        <Typography className={styles.optionText}>{option.text}</Typography>
      </div>
      <div>
        <BiSolidChevronRight className={styles.rightIcon} />
      </div>
    </div>
  );
};
