import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useMutation } from "react-query";

import CustomDrawer from "components/Shared/CustomDrawer";
import Option from "components/Shared/Option";

import { selectIg } from "handlers/connectSocial";

import styles from "./styles.module.css";

const SelectIgAccountDrawer = ({
  userIgs,
  acessToken,
  handleBack,
  visible,
  handleSuccess,
  state,
}) => {
  const [selectedIg, setSelectedIg] = useState(null);

  const selectIgMutation = useMutation(selectIg, {
    onSuccess: () => {
      handleSuccess({
        username: selectedIg?.igUserName,
        profilePic: selectedIg?.igProfilePic,
      });
    },
    onError: (err) => {
      console.log(err);
      message.error("Something went wrong while processing request");
    },
  });

  const handleConnectPlatform = () => {
    if (selectedIg?.igUserId) {
      selectIgMutation({
        data: {
          accessToken: acessToken,
          igUserId: selectedIg.igUserId,
          fbPageId: selectedIg.fbPageId,
        },
        token: state,
      });
    }
  };
  const getErrorText = () => {
    const errorType = selectIgMutation?.error?.response?.data?.status;

    if (!errorType) {
      return "";
    }
    switch (errorType) {
      case "ERR_SCOPES_MISMATCH":
        return " It looks like you didn’t give all the permissions. Please try again";
      case "ERR_NO_INSTA_LINKED_PAGE":
        return "Your selected Facebook page is not connected with  your Instagram";
      case "ERR_NO_FACE_BOOK_PAGE_CREATED":
        return "Please select a facebook page to connect";
      case "ERR_IG_PAGE_IG_USER_NOT_FOUND":
        return "Your Facebook page is not connected with selected Instagram Account";
      case "ERR_IG_OTHER_USER":
        return "There Ig account is connected with a different user";

      default:
        return "Something went wrong while connecting your profile. Please try again ";
    }
  };

  useEffect(() => {
    if (!visible) {
      selectIgMutation.reset();
    }
  }, [visible]);

  // console.log(state, userIgs, acessToken);

  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <div style={{ position: "relative", minHeight: "80vh" }}>
        <p className={styles.heading}>
          Looks like you have
          <br /> selected multiple <br /> Instagram accounts 😅
        </p>
        <p className={styles.label}>
          We support only one account per user at this time.
          <br /> To add multiple account login from another number
        </p>
        <div>
          <p className={styles.headingLabel}>Select one account to connect</p>
          <div className={styles.accountContainer}>
            {userIgs
              ?.filter(
                (item) =>
                  item?.isPageLinkedToInsta && item.isInstaPageSelectedWithPage
              )
              ?.map((ig, key) => {
                return (
                  <Option
                    title={`@${ig?.igUserName}`}
                    isSelected={ig?.igUserName === selectedIg?.igUserName}
                    handleClick={() => {
                      setSelectedIg(ig);
                      setTimeout(() => {
                        selectIgMutation.mutate({
                          data: {
                            accessToken: acessToken,
                            igUserId: ig.igUserId,
                            fbPageId: ig.fbPageId,
                          },
                          token: state,
                        });
                      }, 500);
                    }}
                    isLoading={
                      ig?.igUserName === selectedIg?.igUserName &&
                      selectIgMutation.isLoading
                    }
                    errorText={
                      ig?.igUserName === selectedIg?.igUserName
                        ? getErrorText()
                        : undefined
                    }
                    key={key}
                  />
                );
              })}
          </div>
        </div>
        <div style={{ position: "absolute", bottom: 0 }}>
          <section onClick={handleBack}>
            <p className={styles.footer}>
              Connected wrong Instagram profile? <br />
              <span>Disconnect Instagram and connect again</span>
            </p>
          </section>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default SelectIgAccountDrawer;
