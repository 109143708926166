import React from "react";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./styles.module.css";

// Framer Motion animation variants
const variants = {
  hidden: { opacity: 0, y: -10, transition: { duration: 0.25 } },
  visible: { opacity: 1, y: 0, transition: { duration: 0.25, delay: 0.25 } },
  exit: { opacity: 0, y: -10, transition: { duration: 0.25 } },
};

const OptionContainer = ({
  title,
  body = "",
  status = "notStarted",
  containerClassName,
  containerStyles,
  hardOpen = false,
}) => {
  return (
    <div
      className={clsx(styles.container, containerClassName, {
        [styles.progressContainer]: status === "inProgress" || hardOpen,
      })}
      style={containerStyles}>
      <div className={styles.tileContainer}>
        <p
          className={clsx(styles.title, {
            [styles.disabledTitle]: status === "notStarted",
          })}>
          {title}
        </p>
        <img
          style={{ height: "12px", width: "12px" }}
          alt=""
          src={
            status === "inProgress"
              ? "/icons/progressBlue.svg"
              : status === "notStarted"
              ? "/icons/progressGrey.svg"
              : "/icons/checkedGreen.svg"
          }
        />
      </div>

      <AnimatePresence mode="wait">
        {(status === "inProgress" || hardOpen) && (
          <motion.div
            key="loginMain"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}>
            <div className="body">{body}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OptionContainer;
