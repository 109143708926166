import {
  onAuthStateChanged,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";

import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useQueryClient } from "react-query";
import { message } from "antd";

import { auth } from "../Firebase";

import { registerUser, sendOTP, verifyOTP } from "handlers/me";

// import { registerUser } from "../handlers/me/registerUser";
// import { sendOTP } from "../handlers/me/sendOTP";
// import { verifyOTP } from "../handlers/me/verifyOTP";

//import { sendRequest } from "handlers/sendRequest";

const AuthContext = createContext(null);

let unsubscribe = null;

export default function AuthContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const queryClient = useQueryClient();
  const [hasRunAuthStateListener, setHasRunAuthStateListener] = useState(false);

  // const [expoPushToken, setExpoPushToken] = useState();

  const sendOtp = async ({ phoneNumber, retryCount }) => {
    return sendOTP({ phoneNumber, retryCount });
  };

  const verifyOtp = async ({ otp, uid }) => {
    // const credential = PhoneAuthProvider.credential(verificationCode, otp);
    return verifyOTP({ otp, uid }).then((res) => {
      setLoading(true);
      return res;
    });
  };

  const sendOtpFirebase = async (phoneNumber, appVerifier) => {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    // return sendOTP({ phoneNumber, retryCount });
  };

  const verifyOtpFirebase = async (otp, verificationCode) => {
    const credential = PhoneAuthProvider.credential(verificationCode, otp);
    return signInWithCredential(auth, credential).then((res) => {
      setLoading(true);
      return res;
    });
    // return verifyOTP({ otp, uid }).then((res) => {
    //   setLoading(true);
    //   return res;
    // });
  };

  const signOutDevice = async () => {
    try {
      await signOut(auth);
      setSignedIn(false);
      setCurrentUser(null);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    // console.log("start");

    // Check if the listener has already been executed
    if (!hasRunAuthStateListener) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        // console.log("onAuthStateChanged called", user);
        // console.log("1start");
        if (user !== null) {
          try {
            // Your user registration logic here
            // console.log("hrer");
            await registerUser();
            setSignedIn(true);
            setCurrentUser(user);
          } catch (error) {
            console.error(error);
          }
        } else {
          setSignedIn(false);
          setCurrentUser(null);
        }

        // Set loading to false only after the function has completed
        setLoading(false);

        // Mark that the listener has run
        setHasRunAuthStateListener(true);
      });

      // Unsubscribe when the component unmounts
      return () => {
        // console.log("end", hasRunAuthStateListener);
        if (unsubscribe && hasRunAuthStateListener) {
          unsubscribe();
        }
      };
    } else {
      // If the listener has already run, setLoading to false
      setLoading(false);
    }
  }, [hasRunAuthStateListener]);

  // unsubscribe = onAuthStateChanged(auth, async (user) => {
  //   console.log("this, called", user);
  //   if (user != null) {
  //     try {
  //       await registerUser();
  //       setSignedIn(true);
  //       setCurrentUser(user);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     setSignedIn(false);
  //     setCurrentUser(null);
  //     setLoading(false);
  //   }
  // });

  const customSignIn = (token) => {
    setLoading(true);
    return signInWithCustomToken(auth, token)
      .then(async (user) => {
        if (currentUser) {
          await registerUser();
          setSignedIn(true);
          setCurrentUser(user);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const footerFirstRef = useRef(true);
  const firstRender = useRef(true);

  const value = useMemo(
    () => ({
      currentUser,
      signedIn,
      loading,
      setCurrentUser,
      setSignedIn,
      sendOtp,
      signOutDevice,
      verifyOtp,
      customSignIn,
      footerFirstRef,
      firstRender,
      sendOtpFirebase,
      verifyOtpFirebase,
    }),
    [currentUser, loading, signedIn]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext)
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  return authContext;
};
