import React from "react";

import CustomDrawer from "components/Shared/CustomDrawer";

import styles from "./updatedStyles.module.css";

const InternalError = ({ handleBack, visible }) => {
  return (
    <CustomDrawer visible={visible} handleClose={handleBack}>
      <main>
        <p className={styles.heading}>
          We are currently facing some issues while connecting.
        </p>
      </main>
    </CustomDrawer>
  );
};

export default InternalError;
