import React from "react";
import { Button, Result } from "antd";

class ErrorBoundary extends React.Component {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    console.log(error.toString(), info.componentStack);
  }
  render() {
    // const handleWebviewEvent = () => {
    //   if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(
    //       JSON.stringify({ type: "reloadApp" })
    //     );
    //   }
    // };

    if (this.state.errorMessage) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              onClick={() => {
                window && window.location.reload();
              }}>
              Refresh App
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
