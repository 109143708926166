import React, { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./styles.module.css";
import MainButton from "components/Shared/Button/MainButton";
import { Typography } from "antd";
import InputEdit from "components/Shared/InputEdit";
import OTP from "components/Shared/OTP";
import { AiOutlineWhatsApp } from "react-icons/ai";
import InputMain from "components/Shared/Input";

const LoginOtp = ({ setLoginState }) => {
  const [otp, setOtp] = useState("");

  return (
    <div className={clsx(styles.innerBody)}>
      <InputEdit mainText="+91 9560288563" onClick={() => setLoginState(1)} />
      {/* <InputMain mainText="Enter the OTP" type="OTP" name="OTP" /> */}
      {/* <OTP otp={otp} setOtp={setOtp} /> */}
      {/* <Typography className={clsx(styles.resendText)}>
        Havent received OTP?
        {retryProps.timer !== 30 && retryProps.timer !== 0 ? (
          <div className={clsx(styles.whatsapEnabled)}>
            <AiOutlineWhatsApp /> Get OTP on whatsapp
          </div>
        ) : (
          <div className={clsx(styles.whatsapp)}>
            <AiOutlineWhatsApp /> Get OTP on whatsapp ({retryProps.timer})
          </div>
        )}
      </Typography> */}
      <InputMain mainText="Enter Your OTP" type="tel" />

      <MainButton text="Verify Phone Number" />

      <Typography className={clsx(styles.text2)}>
        By continuing, you agree to Cloutflow's{" "}
        <a href="https://cloutflow.com/terms-condition">Terms of Service</a> and{" "}
        <a href="https://cloutflow.com/privacy-policy"> Privacy Policy.</a>
      </Typography>
    </div>
  );
};

export default LoginOtp;
