import React from "react";
import { BiArrowBack } from "react-icons/bi";
import styles from "./styles.module.css";

const PageHeader = ({ handleClose = () => {} }) => {
  return (
    <div className={styles.main}>
      <BiArrowBack className={styles.back} />
    </div>
  );
};

export default PageHeader;
